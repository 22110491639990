import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, NgZone, ErrorHandler } from '@angular/core'

import { Observable, combineLatest, BehaviorSubject, of, firstValueFrom } from 'rxjs'
import { map, startWith, switchMap, tap } from 'rxjs/operators'
import { KlikattuKirjaus, RaporttienHakuvaihtoehdot } from '../raportit.component'
import { Kirjanpitotili, RaporttiRequest, RaporttiPaakirjaAccountRow, RaporttiPaakirjaData, RaporttiPaakirjaDataResponse } from 'app/_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports-shared'

import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { Timestamp } from 'app/_shared-core/model/common'
import { TilikarttaJaettuService } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'
import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { KirjanpitoNavigationService } from 'app/_angular/service/kirjanpito/kirjanpito-navigation.service'
import { KirjanpitoJaettuService } from 'app/_jaettu-lemonator/service/kirjanpito-jaettu.service'

interface RaporttiPaakirjaAccountRowDisplay extends RaporttiPaakirjaAccountRow {
  showR?: true
  onkoPoistotili?: true
}

interface RaporttiPaakirjaDataDisplay extends RaporttiPaakirjaData {
  r: RaporttiPaakirjaAccountRowDisplay[]
}

@Component({
  selector: '[app-kirjanpito-paakirja]',
  templateUrl: './paakirja.component.html',
  styleUrls: ['./paakirja.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoRaportitPaakirjaComponent implements OnInit {

  @Input() hakuvaihtoehdotObservable: Observable<RaporttienHakuvaihtoehdot>
  @Input() tilitMapObservable: Observable<Map<string, Kirjanpitotili>>
  @Input() paivitaArvotHiljaisestiSubject: BehaviorSubject<number>

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
  paakirjanDataObservable: Observable<RaporttiPaakirjaDataDisplay>

  lastSucessfullyUpdated: Timestamp

  private _taysiVientihistoriaTilitSubject: BehaviorSubject<string[]> = new BehaviorSubject([])
  private _resetViewFunctions: (() => void)[] = []

  constructor(
    private _errorHandler: ErrorHandler,
    private _ngZone: NgZone,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _timestampService: TimestampService,
    private _tilikarttaJaettuService: TilikarttaJaettuService,
    private _tilikarttaService: TilikarttaService,
    private _kirjanpitoNavigationService: KirjanpitoNavigationService,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService
  ) { }

  ngOnInit() {

    const laskelmanRaakadataObservable = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.hakuvaihtoehdotObservable.pipe(
        tap(() => {
          this._setLoadingTrue()
        })
      ),
      this._taysiVientihistoriaTilitSubject,
      this.paivitaArvotHiljaisestiSubject
    ]).pipe(
      switchMap(([asiakas, hakuvaihtoehdot, taysiVientihistoriaTilit, paivita]) => {

        if (!hakuvaihtoehdot?.alkaa || !hakuvaihtoehdot?.loppuu) {
          return of<RaporttiPaakirjaDataDisplay>(null)
        }

        const haku: RaporttiRequest = {
          a: asiakas.avain,
          k: 'fi',
          w: RaporttiType.PAAKIRJA,
          s: hakuvaihtoehdot.alkaa,
          e: hakuvaihtoehdot.loppuu
        }
        if (hakuvaihtoehdot.tilista) { haku.b = hakuvaihtoehdot.tilista }
        if (hakuvaihtoehdot.tiliin) { haku.c = hakuvaihtoehdot.tiliin }
        if (hakuvaihtoehdot.vapaasanahaku?.trim()) { haku.t = hakuvaihtoehdot.vapaasanahaku.trim() }
        if (hakuvaihtoehdot.projekti) { haku.p = hakuvaihtoehdot.projekti }
        if (taysiVientihistoriaTilit.length > 0) { haku.ff = taysiVientihistoriaTilit }

        return this._firebase.functionsCall<RaporttiRequest, RaporttiPaakirjaDataResponse>('kirjanpitoRaportitData', haku).then(res => {
          if (res.e) {
            throw new Error(res.e)
          }
          this.lastSucessfullyUpdated = this._timestampService.now()
          this._setLoadingFalse()
          return res.data as RaporttiPaakirjaDataDisplay
        }).catch(err => {
          console.error('Failed to fetch report data', err)
          this._errorHandler.handleError(err)
        })

      }),
      startWith<RaporttiPaakirjaDataDisplay>({ r: [] })
    )

    const maksutavatMapObservable: Observable<Map<string, AsiakkaanMaksutapa>> = this._asiakasService.nykyisenAsiakkaanKaikkiMaksutavatObservable.pipe(
      map(maksutavat => {
        const paymentMethodsMap = new Map<string, AsiakkaanMaksutapa>()
        for (const m of maksutavat) {
          paymentMethodsMap.set(m.tunniste + '', m)
        }
        return paymentMethodsMap
      })
    )

    const reskontraTilitSetObservable = this._tilikarttaService.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable.pipe(
      map(tilit => {
        return tilit.filter(t => t.reskontraActive)
      }),
      map(tilit => {
        const reskontraTilitSet = new Set<string>()
        for (const tili of tilit) {
          reskontraTilitSet.add(tili.numero)
        }
        return reskontraTilitSet
      })
    )

    this.paakirjanDataObservable = combineLatest([
      this.tilitMapObservable,
      laskelmanRaakadataObservable,
      maksutavatMapObservable,
      reskontraTilitSetObservable
    ]).pipe(
      map(([tiliMap, laskelmanRaakadata, maksutavatMap, reskontraTilitSet]) => {
        if (!tiliMap || !laskelmanRaakadata || !maksutavatMap || !reskontraTilitSet) {
          return null
        }
        if (laskelmanRaakadata.r?.length > 0) {
          for (const r of laskelmanRaakadata.r) {
            if (r.a.length > 3) {
              r.n = r.a + ' ' + this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), 'fi')
            } else {
              r.n = this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), 'fi')
            }
            if (r.d) {
              for (const d of r.d) {
                d.ma = maksutavatMap.get(d.m)?.nimi || ''
              }
            }
            if (reskontraTilitSet.has(r.a)) {
              r.showR = true
            }
            if (this._kirjanpitoJaettuService.onkoRaporttiRivinTiliPoistotili(r, tiliMap)) {
              r.onkoPoistotili = true
            } else {
              delete r.onkoPoistotili
            }
          }
        }
        return laskelmanRaakadata
      })
    )

  }

  private _setLoadingTrue() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(true)
      })
    }, 0)
  }

  private _setLoadingFalse() {
    this._resetViewFunctions.forEach(a => a())
    this._resetViewFunctions = []
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(false)
      })
    }, 0)
  }

  handleClick(event: MouseEvent, data: RaporttiPaakirjaData) {
    const element = event.target as HTMLElement
    if (element.dataset.n) {
      this.kirjaustaKlikattiin.emit({ kirjausnumero: element.dataset.n })
    } else if (element.dataset.a) {
      const arr = this._taysiVientihistoriaTilitSubject.value
      const indexOf = arr.indexOf(element.dataset.a) ?? -1
      if (indexOf > -1) {
        arr.splice(indexOf, 1)
      } else {
        arr.push(element.dataset.a)
      }
      this._taysiVientihistoriaTilitSubject.next(arr)
      const nextRow = element.parentElement?.nextSibling as HTMLTableRowElement
      if (nextRow) {
        nextRow.style.display = 'table-row'
        this._resetViewFunctions.push(function () {
          if (nextRow?.style) {
            nextRow.style.display = 'none'
          }
        })
      }
    } else if (data?.r) {
      const tr = this._findTr(element)
      if (tr?.dataset.i !== undefined) {
        const closestBody = tr.parentElement as HTMLTableSectionElement
        if (closestBody?.dataset?.n) {
          // console.log('secondTd found', closestBody)
          const tilinumero = closestBody.dataset.n
          const tilinItem = data.r.find(a => a.n === tilinumero)
          if (tilinItem?.d) {
            // console.log('item found', tilinItem)
            const d = tilinItem.d[Number(tr?.dataset.i)]
            if (d.l) {
              delete d.l
            } else {
              d.l = true
            }
          }
        }
      }
    }
  }

  private _findTr(elem: HTMLElement): HTMLTableRowElement {
    if (elem?.tagName === 'TR') {
      return elem as HTMLTableRowElement
    } else if (elem?.parentElement?.tagName === 'TR') {
      return elem.parentElement as HTMLTableRowElement
    } else if (elem?.parentElement?.parentElement?.tagName === 'TR') {
      return elem.parentElement.parentElement as HTMLTableRowElement
    }
    return null
  }

  trackAccountRowByAccountNumberFn(index: number, item: RaporttiPaakirjaAccountRow) {
    return item.a
  }

  async avaaReskontraValilehti(tili: string) {
    const hakuvaihtoehdot = await firstValueFrom(this.hakuvaihtoehdotObservable)
    this._kirjanpitoNavigationService.vaihdaPaaTasonValilehti('reskontra')
    this._kirjanpitoNavigationService.valittuKirjanpidonTili(tili)
    this._kirjanpitoNavigationService.valittuRaporttiValinPaattymispaiva(hakuvaihtoehdot?.loppuu)
  }

}
