import { CurrencyService } from "../../_shared-core/service/currency.service";
import { NumberDate } from "../../_shared-core/model/common";
import { DateService } from "../../_shared-core/service/date.service";

export class PoistotService {

  private minimiPoistonMaara: number = 1

  constructor(private _dateService: DateService, private _currencyService: CurrencyService) { }

  /**
   * 
   * Laskee menojäännöspoistot kuluvalle tilikaudelle alkaen halutusta päivämäärästä.
   * 
   * @param poistoprosentti Kuinka paljon poistetaan poistettavasta määrästä (esim. jos poistotilille 1160 on määritetty poistoksi 20% on tämä 20)
   * @param tilikausiLoppuu Milloin kuluva tilikausi päättyy
   * @param alkaenPvm Mistä päivämäärästä aletaan poistamaan
   * @param poistettavaMaara Summa, josta poisto tehdään
   * @param suhteutaPoisto Suhteutetaanko poisto jäljellä oleviin kuukausiin
   * @param jaannosViimeiselleKuukaudelle Poistetaanko myös jäljelle jäävä poistettava määrä kokonaisuudessaan viimeisellä kuukaudella
   * @returns 
   */
  laskePoistot(poistoprosentti: number, tilikausiLoppuu: NumberDate, alkaenPvm: NumberDate, poistettavaMaara: number, suhteutaPoisto: boolean, jaannosViimeiselleKuukaudelle: boolean): { maara: number, pvm: NumberDate }[] {
    if (!poistettavaMaara || poistettavaMaara <= 0) {
      return []
    }

    if (poistettavaMaara < this.minimiPoistonMaara) {
      /** Jos jaettava summa on alle minimipoiston määrän tuodaan kyseinen summa tilikauden viimeiselle päivälle,
       * jotta poisto saadaan päättymään. Muuten pilkottaisiin senttejä ikuisuuteen. */
      return [{
        maara: this._currencyService.roundHalfUp(poistettavaMaara, 2),
        pvm: this._dateService.kuukaudenViimeinenNumber(tilikausiLoppuu)
      }]
    }

    let poistoYhteensa: number

    const kuukaudet = this._dateService.annaKuukaudetAikavalille(this._dateService.numberToLocalDate(alkaenPvm), this._dateService.numberToLocalDate(tilikausiLoppuu))
    const tilikauttaJaljella = kuukaudet.length

    if (suhteutaPoisto) {
      // Poiston määrä vähenee jäjellä olevien kuukausien suhteessa. 
      // Esim. yllä olevassa esimerkissä 5 kuukautta jäljellä, joten koko poiston määrä on 208,33 (2000x0,25/12x5) 
      // ja kuukausittaisen poiston määrä on 41,67
      poistoYhteensa = this._currencyService.roundHalfUp(poistettavaMaara * (poistoprosentti / 100) / 12 * tilikauttaJaljella, 2)
    } else {
      poistoYhteensa = this._currencyService.roundHalfUp(poistettavaMaara * (poistoprosentti / 100), 2)
    }

    const poistoPerKuukausi = this._currencyService.roundHalfUp(poistoYhteensa / tilikauttaJaljella, 2)

    if (jaannosViimeiselleKuukaudelle) {
      poistoYhteensa = poistettavaMaara
    }

    let data: { maara: number, pvm: NumberDate }[] = []

    for (const [index, kuukausi] of kuukaudet.entries()) {
      if (index === kuukaudet.length - 1) {
        // Jakojäännöksen laskenta (voi olla positiivinen tai negatiivinen, riippuen luvuista ja niiden pyöristyksistä)
        const jakojaannos = poistoYhteensa - (tilikauttaJaljella * poistoPerKuukausi)

        let viimeisenKuunSumma: number
        if (jaannosViimeiselleKuukaudelle) {
          viimeisenKuunSumma = poistettavaMaara - data.reduce((sum, d) => sum += d.maara, 0)
        } else {
          viimeisenKuunSumma = poistoPerKuukausi + jakojaannos
        }
        data.push({
          maara: this._currencyService.roundHalfUp(viimeisenKuunSumma, 2),
          pvm: tilikausiLoppuu
        })
      } else {
        data.push({
          maara: this._currencyService.roundHalfUp(poistoPerKuukausi, 2),
          pvm: this._dateService.kuukaudenViimeinenNumber(this._dateService.localDateToNumber(kuukausi.loppu))
        })
      }
    }

    return data
  }

  /**
   * Laskee poistot koko tilikaudelle, eli 'tällöin otetaan edellisen tilikauden lopetussaldo, kerrotaan se annetulla poistoprosentilla 
   * ja jaetaan se tasan tilikauden jokaiselle kuukaudelle.'
   * 
   * @param poistoprosentti Kuinka paljon poistetaan poistettavasta määrästä (esim. jos poistotilille 1160 on määritetty poistoksi 20% on tämä 20)
   * @param tilikausiAlkaa Tilikauden alku
   * @param tilikausiLoppuu Tilikauden loppu
   * @param poistettavaMaara Mistä summasta poisto tehdään (on siis poistotilin edellisen tilikauden päätössaldo)
   * @returns 
   */
  laskePoistotTilikaudelle(poistoprosentti: number, tilikausiAlkaa: NumberDate, tilikausiLoppuu: NumberDate, poistettavaMaara: number): { maara: number, pvm: NumberDate }[] {
    if (!poistettavaMaara || poistettavaMaara <= 0) {
      return []
    }

    if (poistettavaMaara < this.minimiPoistonMaara) {
      /** Jos jaettava summa on alle minimipoiston määrän tuodaan kyseinen summa tilikauden viimeiselle päivälle,
       * jotta poisto saadaan päättymään. Muuten pilkottaisiin senttejä ikuisuuteen. */
      return [{
        maara: this._currencyService.roundHalfUp(poistettavaMaara, 2),
        pvm: this._dateService.kuukaudenViimeinenNumber(tilikausiLoppuu)
      }]
    }

    const kuukaudet = this._dateService.annaKuukaudetAikavalille(this._dateService.numberToLocalDate(tilikausiAlkaa), this._dateService.numberToLocalDate(tilikausiLoppuu))
    const kuukausiaTilikaudessa = kuukaudet.length

    const poistoYhteensa = this._currencyService.roundHalfUp(poistettavaMaara * (poistoprosentti / 100), 2)
    const poistoPerKuukausi = this._currencyService.roundHalfUp(poistoYhteensa / kuukausiaTilikaudessa, 2)

    let data: { maara: number, pvm: NumberDate }[] = []

    for (const [index, kuukausi] of kuukaudet.entries()) {
      if (index === kuukaudet.length - 1) {
        // Jakojäännöksen laskenta (voi olla positiivinen tai negatiivinen, riippuen luvuista ja niiden pyöristyksistä)
        const jakojaannos = poistoYhteensa - (kuukausiaTilikaudessa * poistoPerKuukausi)
        const viimeisenKuunSumma = poistoPerKuukausi + jakojaannos
        data.push({
          maara: this._currencyService.roundHalfUp(viimeisenKuunSumma, 2),
          pvm: tilikausiLoppuu
        })
      } else {
        data.push({
          maara: this._currencyService.roundHalfUp(poistoPerKuukausi, 2),
          pvm: this._dateService.kuukaudenViimeinenNumber(this._dateService.localDateToNumber(kuukausi.loppu))
        })
      }
    }

    return data
  }
}

