
import { Component, OnInit, Inject, ErrorHandler, ViewChild, HostListener, OnDestroy } from '@angular/core'
import { Validators, FormControl, FormGroup } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { Kirjanpitotili, AlvMaaritys, OstoAlvt, MyyntiAlvt, LokalisoituKirjanpitotilinAlvTyyppi, LokalisoidutAlvtyypit, KirjanpitotilinAlvTyyppi, MonikielinenTeksti } from 'app/_jaettu-lemonator/model/kirjanpito'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'

import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

import { debounceTime, startWith, takeUntil } from 'rxjs/operators'
import { Subject, firstValueFrom } from 'rxjs'
import { TuettuKieli } from 'app/_shared-core/model/common'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { FlatKirjanpitotili } from './asiakkaan-tilikartta-edit.component'

export interface AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialogData {
  tili: Kirjanpitotili
  tilit: FlatKirjanpitotili[]
  asiakas: Asiakas
  lisaa: boolean
}

@Component({
  templateUrl: './kirjanpitotili-muokkaa-paatilikartan-tilia.dialog.html'
})
export class AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialog implements OnInit, OnDestroy {

  @ViewChild('nimiFiInput', { read: MatInput, static: true }) nimiFiInput: MatInput

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  commonError: string = ''
  form: FormGroup<{
    numero: FormControl<string>
    nimiFi: FormControl<string>
    nimiEn: FormControl<string>
    alv: FormControl<AlvMaaritys>
    alvtyyppi: FormControl<KirjanpitotilinAlvTyyppi>
    aktiivinen: FormControl<boolean>
    reskontraActive: FormControl<boolean>
    automaattinenPoisto: FormControl<boolean>
    automaattinenPoistoProsentti: FormControl<number>
    automaattinenPoistoTili: FormControl<Kirjanpitotili>
  }>
  name = '' + Math.random()
  lisaa = false
  alvt: AlvMaaritys[] = [].concat(OstoAlvt.kaikki).concat(MyyntiAlvt.kaikki)
  lokalisoidutAlvtyypit: LokalisoituKirjanpitotilinAlvTyyppi[] = LokalisoidutAlvtyypit.kaikki
  suodatetutTilitSubject: Subject<FlatKirjanpitotili[]> = new Subject<[]>

  vertaaAlvMaarityksia: (a: AlvMaaritys, b: AlvMaaritys) => boolean = (a: AlvMaaritys, b: AlvMaaritys): boolean => {
    return a && b && a.tunniste === b.tunniste
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialogData,
    private _dialogRef: MatDialogRef<AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialog, boolean>,
    private _errorHandler: ErrorHandler,
    private _tilikarttaService: TilikarttaService,
    private _ladataanService: LadataanService,
    private _validationService: FormValidationService,
    private _dialog: MatDialog
  ) {
    this.lisaa = this._data.lisaa
  }

  ngOnInit() {

    // Create form
    this.form = new FormGroup({
      'numero': new FormControl<string>({ value: this._data.tili.numero, disabled: true }, [Validators.required, Validators.maxLength(5), Validators.minLength(5)]),
      'nimiFi': new FormControl<string>(this._data.tili.localisedName?.fi ?? this._data.tili.nimi, [Validators.required]),
      'nimiEn': new FormControl<string>(this._data.tili.localisedName?.en ?? null, [Validators.required]),
      'alv': new FormControl<AlvMaaritys>(this._data.tili.oletusAlvKasittely, [Validators.required]),
      'alvtyyppi': new FormControl<KirjanpitotilinAlvTyyppi>(null, [Validators.required]),
      'aktiivinen': new FormControl<boolean>({ value: this._data.tili.aktiivinen, disabled: true }, []),
      'reskontraActive': new FormControl<boolean>(this._data.tili.reskontraActive),
      'automaattinenPoisto': new FormControl<boolean>(!!this._data.tili.poistotiedot),
      'automaattinenPoistoProsentti': new FormControl<number>(this._data.tili?.poistotiedot?.poistoprosentti || 25, [Validators.min(0), Validators.max(100)]),
      'automaattinenPoistoTili': new FormControl<Kirjanpitotili>(this._data.tilit.find(tili => tili.kirjanpitotili.numero === this._data.tili?.poistotiedot?.poistotili)?.kirjanpitotili || null, [Validators.required]),
    })

    this.alvtyyppi.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tyyppi => {
      if (tyyppi === KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA) {
        this.alv.setValue(null)
        this.alv.disable()
      } else {
        this.alv.enable()
      }
    })

    this.alvtyyppi.setValue(this._data.tili.alvTyyppi)

    this.nimiFiInput.focus()

    this.reskontraActive.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async isActive => {
      if (
        this._data.tili.reskontraActive &&  // Was active before
        !isActive // But was just marked as NOT active
      ) {
        const dialogData: AreYouSureDialogData = {
          text: 'Jos reskontra laitetaan pois päältä, kaikki reskontramerkinnät poistetaan.',
          rightAction: 'Kyllä',
        }
        const isSure = await firstValueFrom(this._dialog.open(AreYouSureDialog, { data: dialogData }).afterClosed())

        if (!isSure) {
          this.reskontraActive.setValue(true)
        }
      }
    })

    this.automaattinenPoisto.valueChanges.pipe(
      startWith(this.automaattinenPoisto.value),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(automaattinenPoisto => {
      if (automaattinenPoisto) {
        this.automaattinenPoistoProsentti.enable()
        this.automaattinenPoistoTili.enable()
      } else {
        this.automaattinenPoistoProsentti.disable()
        this.automaattinenPoistoTili.disable()
      }
    })

    this.automaattinenPoistoTili.valueChanges.pipe(
      debounceTime(50),
      startWith(''),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      const suodatetut = typeof value === 'string' ? this._data.tilit.filter(tili => tili.kirjanpitotili.nimi.toLowerCase().indexOf(value) === 0 || tili.kirjanpitotili.numero.toLowerCase().indexOf(value) === 0) : this._data.tilit
      this.suodatetutTilitSubject.next(suodatetut)
    })
  }



  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  get numero() {
    return this.form.get('numero')
  }

  get nimiFi() {
    return this.form.get('nimiFi')
  }

  get nimiEn() {
    return this.form.get('nimiEn')
  }

  get alv() {
    return this.form.get('alv')
  }

  get alvtyyppi() {
    return this.form.get('alvtyyppi')
  }

  get aktiivinen() {
    return this.form.get('aktiivinen')
  }

  get reskontraActive() {
    return this.form.get('reskontraActive')
  }

  get automaattinenPoisto() {
    return this.form.get('automaattinenPoisto')
  }

  get automaattinenPoistoProsentti() {
    return this.form.get('automaattinenPoistoProsentti')
  }

  get automaattinenPoistoTili() {
    return this.form.get('automaattinenPoistoTili')
  }

  displayPoistotili(tili: Kirjanpitotili): string {
    return (tili && tili.nimi && tili.numero) ? `${tili.numero} ${tili.nimi}` : ''
  }

  @HostListener('document:keydown.esc')
  peruuta() {
    this._dialogRef.close(false)
  }

  poista() {
    this._ladataanService.aloitaLataaminen()
    this._tilikarttaService.poistaAsiakkaanPaatilikartanYliajoTili(this._data.asiakas, this._data.tili).then(() => {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close(true)
    }).catch(error => {
      this._ladataanService.lopetaLataaminen()
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.'
      this._errorHandler.handleError(error)
    })
  }

  @HostListener('document:keydown.enter')
  async tallenna() {

    if (this.form.invalid || !this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    const localisedName: MonikielinenTeksti = {
      'fi': null,
      'en': null
    }

    if (this.nimiFi.value) {
      localisedName.fi = this.nimiFi.value
    }
    if (this.nimiEn.value) {
      localisedName.en = this.nimiEn.value
    }
    const tili: Kirjanpitotili = {
      aktiivinen: !!this.aktiivinen.value,
      numero: this.numero.value,
      nimi: this.nimiFi.value,
      oletusAlvKasittely: this.alv.enabled ? this.alv.value : null,
      vanhempi: this._data.tili.vanhempi,
      alvTyyppi: this.alvtyyppi.value,
      localisedName: localisedName
    }

    if (this.automaattinenPoisto.value) {
      tili.poistotiedot = {
        poistoprosentti: this.automaattinenPoistoProsentti.value,
        poistotili: this.automaattinenPoistoTili.value.numero,
      }
    }

    if (!this.reskontraActive.disabled) {
      tili.reskontraActive = this.reskontraActive.value ?? false
    }

    this._tilikarttaService.tallennaAsiakkaanPaatilikartanYliajoTili(this._data.asiakas, tili).then(() => {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close(true)
    }).catch(error => {
      this._ladataanService.lopetaLataaminen()
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.'
      this._errorHandler.handleError(error)
    })

  }

}
