<div class="list-page laskut list-loading">

  <app-asiakas-otsikko otsikko="Selaa laskuja" [asiakas]="asiakas"></app-asiakas-otsikko>

  <!-- <form [formGroup]="form" novalidate class="ylaosan-kontrollit"> -->

  <!-- <div *ngIf="(tableViewOptionsObservable | async) let tbvOpts" class="lisaa-nappula-container">
      <button *ngIf="tbvOpts.showAddButton else lemonatorissa" mat-button (click)="lisaa()" type="button" class="primarybutton mat-elevation-z4">
        <mat-icon>add</mat-icon>
        {{'laskut.lisaa' | translate | async}}
      </button>
      <ng-template #lemonatorissa>
        Luo asiakkaille lähetettävät laskut Lemonatorissa.
      </ng-template>
    </div> -->

  <!-- <div class="hakukontrollit">

      <mat-form-field floatPlaceholder="never">
        <mat-label>{{ 'laskut.nayta' | translate | async }}</mat-label>
        <mat-select formControlName="tila">
          <mat-option *ngFor="let tila of laskunTilatObservable | async" [value]="tila.tunnus">
            {{ tila.nimi }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matPrefix class="listaus-haku-ikoni">search</mat-icon>
        <mat-label>{{ 'laskut.etsi' | translate | async }}</mat-label>
        <input type="text" [name]="name" formControlName="nimiFirestore" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'laskut.rajaa-kuukauden-mukaan' | translate | async }}</mat-label>
        <input matInput [name]="name" [matDatepicker]="dp" formControlName="vuosikk">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="year" (monthSelected)="chosenMonthHandler($event, dp)" panelClass="kuukausi-valitsin"></mat-datepicker>
      </mat-form-field>

    </div> -->

  <!-- </form> -->

  <div *ngIf="avoinnaTo || avoinnaTo == 0" class="summatContainer">
    <div class="summat">
      <div>
        <!-- <div>{{avoinnaFrom}} -> {{avoinnaTo}}</div> -->
        <div class="otsikko">{{'laskut.summat.avoinna' | translate | async}}</div>
        <div class="maara blue-text" appCountTo [step]="16.667" [countTo]="avoinnaTo" [countFrom]="avoinnaFrom" [duration]="1" (countToChange)="countToAvoinna = $event" (countToEnd)="onCountToEnd()">{{ countToAvoinna | lmoney: valuutta | async}}</div>
      </div>
      <div>
        <!-- <div>{{eraantynytFrom}} -> {{eraantynytTo}}</div> -->
        <div class="otsikko">{{'laskut.summat.eraantynyt' | translate | async}}</div>
        <div class="maara red-text" [class.red-text]="eraantynytTo != 0" [class.green-text]="eraantynytTo == 0" appCountTo [step]="16.667" [countTo]="eraantynytTo" [countFrom]="eraantynytFrom" [duration]="1" (countToChange)="countToEraantynyt = $event" (countToEnd)="onCountToEnd()">
          {{countToEraantynyt | lmoney: valuutta | async}}</div>
      </div>
      <!-- <div class="huomautukset">
        <span *ngIf="naytaHuomautus" style="margin-left: 2em;" (click)="avaaHuomautus()">{{'laskut.summat.huomautus' | translate | async}}</span>
      </div> -->
    </div>
  </div>

  <div style="max-width: 1200px; overflow-x: auto; margin: 0 5px; text-align: center;">
    <div style="position: relative; min-height: 200px; display: inline-block; text-align: left;">
      <table class="laskut-table" matSort matSortDisabled [matSortDisableClear]="true" style="border-spacing: 0; margin: 0 auto;">
        <thead *ngIf="{ showCommentsColumn: (tableViewOptionsObservable | async)?.showCommentsColumn } as tbvOpts">
          <tr class="mat-mdc-header-row">
            <th mat-sort-header="tila" class="mat-mdc-header-cell mat-column-tila"> {{'laskut.tila' | translate | async}} </th>
            <th mat-sort-header="nro" class="mat-mdc-header-cell mat-column-nro"> {{'laskut.numero' | translate | async}} </th>
            <th mat-sort-header="asiakas" class="mat-mdc-header-cell mat-column-asiakas"> {{'laskut.asiakas' | translate | async}} </th>
            <th mat-sort-header="pvm" class="mat-mdc-header-cell mat-column-pvm"> {{'laskut.laskun-pvm' | translate | async}} </th>
            <th mat-sort-header="erapvm" class="mat-mdc-header-cell mat-column-erapvm"> {{'laskut.erapaiva' | translate | async}} </th>
            <th mat-sort-header="summa" class="mat-mdc-header-cell mat-column-summa align-header-right"> {{'laskut.summa' | translate | async}} </th>
            <th mat-sort-header="avoinna" class="mat-mdc-header-cell mat-column-avoinna align-header-right"> {{'laskut.avoinna' | translate | async}} </th>
            <th class="mat-mdc-header-cell mat-column-merkitse"> {{'laskut.merkitse-maksetuksi' | translate | async}} </th>
            <th *ngIf="tbvOpts.showCommentsColumn" class="mat-mdc-header-cell mat-column-kommentti"> {{'laskut.kommentti' | translate | async}} </th>
          </tr>
        </thead>
        <tbody *ngIf="{ showCommentsColumn: (tableViewOptionsObservable | async)?.showCommentsColumn } as tbvOpts">
          <tr *ngFor="let row of nakyvatRivitObservable | async" (click)="muokkaa(row)" class="mat-mdc-row" [class.lemon-lapsi]="row.tyyppi == 'l'" [class.lemon-lapsi-viimeinen]="row.tyyppi == 'lv'" [class.lemon-vanhempi]="row.tyyppi == 'v'">
            <td class="mat-mdc-cell mat-column-tila" [class.blue-text]="row.vari == 'blue'" [class.yellow-text]="row.vari == 'yellow'" [class.green-text]="row.vari == 'green'" [class.red-text]="row.vari == 'red'" [class.purple-text]="row.vari == 'purple'" [class.mint-text]="row.vari == 'mint'">
              <ng-container *ngIf="row.lahetysEpaonnistui; then huutomerkki else tyhja">

              </ng-container>
              <ng-template #huutomerkki>
                <table style="border-spacing: 0;">
                  <tr>
                    <td [matTooltip]="'laskut.lahettaminen-epaonnistui' | translate | async" style="color: red; font-size: 32px; font-weight: bold; vertical-align: middle; padding-right: 4px; border-bottom: none;">
                      !
                    </td>
                    <td style="vertical-align: top; border-bottom: none;">
                      <ng-container *ngIf="row.tyyppi == 't' || row.tyyppi == 'v'">
                        {{'lasku.tila.' + row.tila | translate | async}}
                      </ng-container>
                    </td>
                  </tr>
                </table>
              </ng-template>
              <ng-template #tyhja>
                <ng-container *ngIf="row.tyyppi == 't' || row.tyyppi == 'v'">
                  {{'lasku.tila.' + row.tila | translate | async}}
                </ng-container>
              </ng-template>
            </td>
            <td class="mat-mdc-cell mat-column-nro"> {{row.nro}} </td>
            <td class="mat-mdc-cell mat-column-asiakas"> {{row.asiakas}} </td>
            <td class="mat-mdc-cell mat-column-pvm"> {{row.pvm | lokaalipvm | async}} </td>
            <td class="mat-mdc-cell mat-column-erapvm"> {{row.erapvm | lokaalipvm | async}} </td>
            <td class="mat-mdc-cell mat-column-summa"> {{row.summa | lmoney: row.valuutta | async}} </td>
            <td class="mat-mdc-cell mat-column-avoinna">
              <ng-container *ngIf="(row.tyyppi == 'v' || row.tyyppi == 't') && row.tila !== 'lu' && row.nrotyyppi !== 'h'">
                {{ row.avoinna | lmoney: row.valuutta | async }}
              </ng-container>
            </td>
            <td class="mat-mdc-cell mat-column-merkitse">
              <!-- <button mat-button [class.blue-text]="row.vari == 'blue'" [class.yellow-text]="row.vari == 'yellow'" [class.green-text]="row.vari == 'green'" [class.red-text]="row.vari == 'red'" [class.purple-text]="row.vari == 'purple'" [class.mint-text]="row.vari == 'mint'" [class.mat-elevation-z4]="row.avoinna > 0" class="maksetuksi-button"
              *ngIf="(row.tyyppi == 't' || row.tyyppi == 'v') && row.tila != 'l' && row.tila != 'lu' && row.tila != 'mi' && row.tila != 'h'" (click)="merkitseMaksetuksi(row); $event.stopPropagation();">
              <mat-icon>done</mat-icon>
            </button> -->
            </td>
            <td *ngIf="tbvOpts.showCommentsColumn" class="mat-mdc-cell mat-column-kommentti">
              <!-- (click)="modifyComment(row.juurilasku); $event.stopPropagation();" -->
              <pre *ngIf="row?.juurilasku?.kommentti let kommentti">{{kommentti}}</pre>
              <!-- <button *ngIf="!row?.juurilasku?.kommentti" mat-button class="kommentti-button mat-elevation-z4" (click)="modifyComment(row.juurilasku); $event.stopPropagation();">
              +
            </button> -->
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="{ showCommentsColumn: (tableViewOptionsObservable | async)?.showCommentsColumn } as tbvOpts">
          <tr class="mat-mdc-footer-row dark-gray-text" *ngFor="let rivi of yhteensaRivitObservable | async">
            <td class="mat-mdc-footer-cell mat-column-tila" style="border-bottom-color: transparent;">&nbsp;</td>
            <td class="mat-mdc-footer-cell mat-column-nro" style="border-bottom-color: transparent;"></td>
            <td class="mat-mdc-footer-cell mat-column-asiakas" style="border-bottom-color: transparent; text-align: right; padding-right: 10px;" colspan="3">Näkyvät rivit yhteensä:</td>
            <!-- <td class="mat-mdc-footer-cell mat-column-erapvm" style="border-bottom-color: transparent;"></td> -->
            <!-- <td class="mat-mdc-footer-cell mat-column-pvm" style="border-bottom-color: transparent;"            *ngIf="naytaPvm">Näkyvät rivit yhteensä:</td> -->
            <td class="mat-mdc-footer-cell mat-column-summa" style="border-bottom-color: transparent;"> {{rivi.laskutettu | lmoney: rivi.valuutta | async}} </td>
            <td class="mat-mdc-footer-cell mat-column-avoinna" style="border-bottom-color: transparent;"> {{rivi.avoinna | lmoney: rivi.valuutta | async}} </td>
            <td class="mat-mdc-footer-cell mat-column-merkitse" style="border-bottom-color: transparent;"></td>
            <td *ngIf="tbvOpts.showCommentsColumn" class="mat-mdc-footer-cell mat-column-kommentti"></td>
          </tr>
        </tfoot>
      </table>
      <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-items: center; align-items: center;" *ngIf="lataaObservable | async">
        <div lemon-loading [config]="loadingSettings" [show]="true"></div>
      </div>
      <div class="table-ladataan" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-items: center; align-items: center;" *ngIf="naytaEiYhtaanObservable | async">
        <div style="width: 100%; padding-top: 40px; text-align: center;">
          {{'laskut.hakuehdoilla-ei-yhtaan-tulosta' | translate | async}}
        </div>
      </div>
    </div>
  </div>
  <div style="min-height: 200px; max-width: 1200px; overflow-x: auto; margin: 0 5px;">
    <!-- <mat-table class="laskut-table" matSort [dataSource]="dataSource?.dataSourceTransformed" [matSortDisableClear]="true" style="min-height: 160px;">

      <ng-container matColumnDef="nro">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.numero' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.nro}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="asiakas">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.asiakas' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.asiakas}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="erapvm">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.erapaiva' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.erapvm | lokaalipvm | async }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pvm">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.laskun-pvm' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.pvm | lokaalipvm | async }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="summa">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.summa' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.summa | lmoney: row.valuutta | async }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="avoinna">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.avoinna' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="(row.tyyppi == 'v' || row.tyyppi == 't') && row.tila !== 'lu' && row.nrotyyppi !== 'h'">
            {{ row.avoinna | lmoney: row.valuutta | async }}
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tila">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'laskut.tila' | translate | async}} </mat-header-cell>
        <mat-cell *matCellDef="let row"
          [class.blue-text]   = "row.vari == 'blue'"
          [class.yellow-text] = "row.vari == 'yellow'"
          [class.green-text]  = "row.vari == 'green'"
          [class.red-text]    = "row.vari == 'red'"
          [class.purple-text] = "row.vari == 'purple'"
          [class.mint-text]   = "row.vari == 'mint'"
          >
          <ng-container *ngIf="row.lahetysEpaonnistui; then huutomerkki else tyhja">

          </ng-container>
          <ng-template #huutomerkki>
            <table style="border-spacing: 0;">
              <tr>
                <td [matTooltip]="'laskut.lahettaminen-epaonnistui' | translate | async" style="color: red; font-size: 32px; font-weight: bold; vertical-align: middle; padding-right: 4px;">
                  !
                </td>
                <td style="vertical-align: top;">
                  <ng-container *ngIf="row.tyyppi == 't' || row.tyyppi == 'v'">
                    {{'lasku.tila.' + row.tila | translate | async}}
                  </ng-container>
                </td>
              </tr>
            </table>
          </ng-template>
          <ng-template #tyhja>
            <ng-container *ngIf="row.tyyppi == 't' || row.tyyppi == 'v'">
              {{'lasku.tila.' + row.tila | translate | async}}
            </ng-container>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="merkitse">
        <mat-header-cell *matHeaderCellDef><span style="padding-left: 5px; padding-right: 10px;">{{'laskut.merkitse-maksetuksi' | translate | async}}</span></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-button
            [class.blue-text]   = "row.vari == 'blue'"
            [class.yellow-text] = "row.vari == 'yellow'"
            [class.green-text]  = "row.vari == 'green'"
            [class.red-text]    = "row.vari == 'red'"
            [class.purple-text] = "row.vari == 'purple'"
            [class.mint-text]   = "row.vari == 'mint'"
            [class.mat-elevation-z4] = "row.avoinna > 0"
            class="maksetuksi-button"
            *ngIf="(row.tyyppi == 't' || row.tyyppi == 'v') && row.tila != 'l' && row.tila != 'lu' && row.tila != 'mi' && row.tila != 'h'"
            (click)="merkitseMaksetuksi(row); $event.stopPropagation();">
            <mat-icon>done</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="propertiesToDisplay"></mat-header-row>
      <mat-row (click)="muokkaa(row)"
        *matRowDef="let row; columns: propertiesToDisplay"
        [class.lemon-lapsi]="row.tyyppi == 'l'"
        [class.lemon-lapsi-viimeinen]="row.tyyppi == 'lv'"
        [class.lemon-vanhempi]="row.tyyppi == 'v'"></mat-row>

    </mat-table> -->

    <mat-paginator #paginator [length]="dataSource?.rivienMaaraObservable | async" [pageSize]="dataSource?.page?.pageSize" [pageIndex]="dataSource?.page?.pageIndex" [pageSizeOptions]="[20, 50, 200, 500, 1000, 5000]">
    </mat-paginator>

  </div>

</div>