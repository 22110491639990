
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ErrorHandler, Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Platform } from '@angular/cdk/platform'
import { MatDialog } from '@angular/material/dialog'


import { LoadingConfig } from 'app/_jaettu-angular/_components/loading.component'
import { LaskuKatseleComponentExistingData, LaskuKatseleComponentDataResolve } from '../../_angular/_resolvers/lasku.resolve'
import { LaskuService } from '../../_angular/service/lasku/lasku.service'

import { LemonKuukausiDateAdapter } from '../../_jaettu-angular/_material/LemonKuukausiDateAdapter'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { WindowSizeService } from '../../_jaettu-angular/service/window.service'

import { DateService } from '../../_shared-core/service/date.service'
import { LaskunTila, LaskunAsiakas, LaskunListaustietorivi, LaskuBase, Lasku, LaskunListaustyyppi, LaskuTotalsPerCurrency } from '../../_jaettu/model/lasku'

import { LaskunLokalisoituTila } from '../laskut.firestore.datasource'

import { BehaviorSubject, EMPTY, Observable, Subject, combineLatest, firstValueFrom, iif, of } from 'rxjs'
import { debounceTime, map, startWith, switchMap, takeUntil, throttleTime } from 'rxjs/operators'
import { Hakukriteerit, LaskutSelaaUusiDataSourceService } from './laskut-selaa.uusi.datasource.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { FirestoreIndeksoija } from 'app/_jaettu/service/firestore.indeksoija'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'


export type LaskuProperties = 'nro' | 'pvm' | 'erapvm' | 'summa' | 'avoinna' | 'tila' | 'lataa' | undefined

export interface SummienRullausData {
  juurilasku: Lasku
  kasiteltava: LaskuBase
  edellinenLaskunTila: LaskunTila
}

interface YhteensaRivi {
  valuutta: string
  avoinna: number
  laskutettu: number
}

@Component({
  templateUrl: './laskut-selaa.component.html',
  styleUrls: ['./laskut-selaa.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] },
  ]
})
export class LaskutSelaaComponent implements OnInit, AfterViewInit, OnDestroy {

  asiakas: Asiakas = null

  // @ViewChild('laskutaulu', { static: true }) laskutaulu: ElementRef
  @ViewChild(MatPaginator, { static: true }) _paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  private _ngUnsubscribe = new Subject<void>()
  // private _taydennaAsetuksetDialogi: MatDialogRef<LaskuTaydennaAsetuksetDialog> = null
  // private _kommenttiDialogi: MatDialogRef<LaskuKommenttiDialog> = null

  form: UntypedFormGroup

  // propertiesToDisplay: TuoteProperties[] = []
  laskunTilatObservable: Observable<LaskunLokalisoituTila[]>
  tableViewOptionsObservable: Observable<{ showAddButton: boolean, showCommentsColumn: boolean }>

  nakyvatRivitObservable: Observable<LaskunListaustietorivi[]>
  yhteensaRivitObservable: Observable<YhteensaRivi[]>

  naytaEiYhtaanObservable: Observable<boolean>
  lataaObservable: Observable<boolean>
  name = 'asdf' + Math.random()

  countToAvoinna: number = 0
  countToEraantynyt: number = 0
  avoinnaTo: number = 0
  avoinnaFrom: number = 0
  eraantynytTo: number = 0
  eraantynytFrom: number = 0
  valuutta: string = ''

  private _alustaSummat = true
  private _pauser = new Subject<boolean>()

  loadingSettings: LoadingConfig = {
    fullScreenBackdrop: false,
    backdropBackgroundColour: 'rgba(0,0,0,0.15)',
    backdropBorderRadius: '4px',
    primaryColour: 'rgba(255, 255, 255, 1)',
    secondaryColour: 'rgba(255, 255, 255, 1)', // rgba(252, 238, 32, .15)
    tertiaryColour: 'rgba(255, 255, 255, 1)'
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler,
    private dialog: MatDialog,
    private laskuService: LaskuService,
    private asiakasService: AsiakasService,
    private laskuSummatService: LaskuSummatService,
    private firestoreIndeksoija: FirestoreIndeksoija,
    private laskuKatseleComponentDataResolve: LaskuKatseleComponentDataResolve,
    private windowSizeService: WindowSizeService,
    private translationService: LemonTranslationService,
    private dateAdapter: DateAdapter<Date>,
    private _dataSource: LaskutSelaaUusiDataSourceService,
    // private versionTarkistaja: VersionTarkistusPalvelu,
    // private asetustenOletuksetService: AsetustenOletuksetService
  ) {
    this.lataaObservable = _dataSource.lataaObservable
    this.naytaEiYhtaanObservable = combineLatest([this._dataSource.rivienMaaraObservable, this._dataSource.lataaObservable]).pipe(
      map(([rivienMaara, lataa]) => {
        if (lataa) {
          return false
        }
        return rivienMaara < 1
      })
    )
  }

  ngOnInit() {

    this.asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(asiakas => this.asiakas = asiakas)

    // this.versionTarkistaja.sovelluksenVersioObservable.pipe(
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(versiotiedot => {
    //   this.versionTarkistaja.tarkistaVersio(versiotiedot)
    // })

    this.asetaListauksenKolumnit()

    this.translationService.currentLanguageObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kieli => {
      this.dateAdapter.setLocale(kieli)
    })

    // this.laskuService.asetuksetObservable.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(asetukset => {
    //   if (asetukset && !asetukset.summahuomautusPiilotettu) {
    //     this.naytaHuomautus = true
    //   } else {
    //     this.naytaHuomautus = false
    //   }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    this._pauser.pipe(
      switchMap(aktiivinen => {
        return iif(() => aktiivinen, this.laskuSummatService.summatObservable, EMPTY)
      }),
      throttleTime(1100, undefined, { leading: true, trailing: true }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(summat => {
      // console.log('summat', JSON.stringify(summat))
      if (summat) {

        // Mikä valuutta
        this.valuutta = summat.valuutta

        // Mistä rullataan
        this.avoinnaFrom = summat.avoinnaAlkuperainen
        this.eraantynytFrom = summat.eraantynytAlkuperainen

        // Mihin rullataan
        if (this._alustaSummat) {
          this._alustaSummat = false
          setTimeout(() => {
            this.avoinnaTo = summat.avoinna
            this.eraantynytTo = summat.eraantynyt
          }, 150)
        } else {
          this.avoinnaTo = summat.avoinna
          this.eraantynytTo = summat.eraantynyt
        }

      }
    }, error => {
      this.errorHandler.handleError(error)
    })
    this._pauser.next(true)

    this.laskunTilatObservable = this.dataSource.laskunTilatObservable
    this.tableViewOptionsObservable = this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(asiakas => {
        return { showAddButton: false, showCommentsColumn: asiakas?.asiakasId === 9 }
      }),
      startWith({ showAddButton: true, showCommentsColumn: false })
    )

    // Luo form, HUOM! Default arvot laitetaan hakutiedotObservable subscribessa
    this.form = new UntypedFormGroup({
      'nimiFirestore': new UntypedFormControl(null, []),
      'vuosikk': new UntypedFormControl(null, []),
      // 'vuosikkkohde': new FormControl( vuosiKk.kohde, [] ),
      'tila': new UntypedFormControl(null, [])
    })

    // Jos hakutiedot muuttuvat, muuta käyttöliittymää sen mukaan
    this.dataSource.hakutiedotObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(hakutiedot => {

      if (this.form.get('nimiFirestore').value !== hakutiedot.hakukriteerit.vapaahaku) {
        this.form.get('nimiFirestore').setValue(hakutiedot.hakukriteerit.vapaahaku, { emitModelToViewChange: true, emitViewToModelChange: false })
      }

      this._setKk(hakutiedot.hakukriteerit)
      // if (this.form.get('vuosikkkohde').value !== hakutiedot.hakukriteerit.vuosikk.kohde) {
      // this.form.get('vuosikkkohde'.setValue(vuosiKk.kohde, { emitModelToViewChange: true, emitViewToModelChange: false })
      // }
      if (this.form.get('tila').value !== hakutiedot.hakukriteerit.tila) {
        this.form.get('tila').setValue(hakutiedot.hakukriteerit.tila, { emitModelToViewChange: true, emitViewToModelChange: false })
      }
    })

    // this.form.get('vuosikkkohde').valueChanges.subscribe((value: 'p' | 'e') => {
    //   this.dataSource.vuosiKkKohde = value
    // })
    this.form.get('vuosikk').valueChanges.subscribe((value: Date) => {
      console.log('asetettu vuosikk; ', value)
      if (value) {
        this.dataSource.setVuosiKk(value.getFullYear(), value.getMonth())
      } else {
        this.dataSource.setVuosiKk(null, null)
      }
    })
    this.form.get('tila').valueChanges.subscribe((value: LaskunTila) => {
      this.dataSource.tila = value
    })
    this.form.get('nimiFirestore').valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        const val = this.firestoreIndeksoija.poistaValimerkitJaValilyonnit(value)
        if (val.length > 2) {
          this.dataSource.search = value
        } else {
          this.dataSource.search = null
        }
      } else {
        this.dataSource.search = null
      }
    })

    // const currentSort = this.dataSource.sort
    // this.sort.sort({
    //   id: currentSort.active,
    //   disableClear: true,
    //   start: currentSort.direction === 'asc' ? 'asc' : 'desc'
    // })
    // this.sort.sortChange.subscribe(sort => {
    //   this.dataSource.sort = sort
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    this._paginator.page.subscribe(page => {
      this.dataSource.page = page
    }, error => {
      this.errorHandler.handleError(error)
    })

    // this.route.data.pipe(
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe((data: { data: LaskutComponentData }) => {
    //   if (data && data.data && data.data.nollaaHakuasetukset) {
    //     this.dataSource.resetSearchToDefaults()
    //   }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })


    this.nakyvatRivitObservable = this.dataSource.dataSourceTransformed.connect(null)
    this.yhteensaRivitObservable = this.nakyvatRivitObservable.pipe(
      map(laskut => {
        const valuuttaMap: { [valuuttaKey: string]: YhteensaRivi } = {}
        for (const lasku of laskut) {
          let valuutanYhteensaRivi = valuuttaMap[lasku.valuutta]
          if (!valuutanYhteensaRivi) {
            valuutanYhteensaRivi = {
              avoinna: 0,
              laskutettu: 0,
              valuutta: lasku.valuutta
            }
            valuuttaMap[lasku.valuutta] = valuutanYhteensaRivi
          }
          if (lasku.tila !== LaskunTila.luonnos && (lasku.tyyppi === LaskunListaustyyppi.TAVALLINEN || lasku.tyyppi === LaskunListaustyyppi.VANHEMPI)) {
            // console.log('Lasketaan avoinna ' + lasku.nro, lasku.avoinna)
            valuutanYhteensaRivi.avoinna += lasku.avoinna
          }
          valuutanYhteensaRivi.laskutettu += lasku.summa
          // console.log('Lasketaan laskutettu ' + lasku.nro, lasku.summa)
        }
        return Object.keys(valuuttaMap).map(a => valuuttaMap[a]).sort((a, b) => { return a.laskutettu - b.laskutettu })
      }),
      lemonShare()
    )

  }

  private _setKk(hakukriteerit: Hakukriteerit) {
    const vuosiKk2 = hakukriteerit.vuosikk
    if (
      vuosiKk2 &&
      vuosiKk2.kk !== null &&
      vuosiKk2.kk !== undefined &&
      vuosiKk2.vuosi !== null &&
      vuosiKk2.vuosi !== undefined
    ) {
      const date2 = new Date(vuosiKk2.vuosi, vuosiKk2.kk, 1)
      if (!this.form.get('vuosikk').value) {
        this.form.get('vuosikk').setValue(date2, { emitModelToViewChange: true, emitViewToModelChange: false })
      } else {
        const existingDate = this.form.get('vuosikk').value as Date
        if (existingDate.getFullYear() !== date2.getFullYear() || existingDate.getMonth() !== date2.getMonth()) {
          this.form.get('vuosikk').setValue(date2, { emitModelToViewChange: true, emitViewToModelChange: false })
        }
      }
    } else {
      if (this.form.get('vuosikk').value !== null) {
        this.form.get('vuosikk').setValue(null, { emitModelToViewChange: true, emitViewToModelChange: false })
      }
    }
  }

  get dataSource(): LaskutSelaaUusiDataSourceService {
    return this._dataSource
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.form.get('vuosikk').setValue(normalizedMonth)
    datepicker.close()
  }

  ngAfterViewInit() {
    this.windowSizeService.sizeObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(koko => {
      setTimeout(() => {
        this.asetaListauksenKolumnit()
      }, 250)
    })
  }

  private asetaListauksenKolumnit() {

    // this.naytaNro = true
    // this.naytaPvm = true
    // this.naytaSumma = true

    // const leveys = this.laskutaulu.nativeElement.offsetWidth
    // // if (tablenKoko < 500) {
    // //   this.propertiesToDisplay = [               'asiakas',         'erapvm',         'avoinna', 'merkitse']
    // // } else
    // if (leveys < 600) {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'erapvm', 'avoinna', 'merkitse']
    //   // this.naytaNro = false
    //   this.naytaPvm = false
    //   this.naytaSumma = false
    // } else if (leveys < 700) {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'erapvm', 'summa', 'avoinna', 'merkitse']
    //   // this.naytaNro = false
    //   this.naytaPvm = false
    // } else if (leveys < 800) {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'pvm', 'erapvm', 'summa', 'avoinna', 'merkitse']
    //   // this.naytaNro = false
    // } else {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'pvm', 'erapvm', 'summa', 'avoinna', 'merkitse']
    // }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  getDisplayFn() {
    return (val) => this.display(val)
  }

  private display(laskunAsiakas: LaskunAsiakas): string {
    if (laskunAsiakas && laskunAsiakas.nimi) {
      return laskunAsiakas.nimi
    }
    return ''
  }

  onCountToEnd() {
    // this.laskuSummatService.numerotRullattiin()
  }

  async muokkaa(rivi: LaskunListaustietorivi) {

    const existing: LaskuKatseleComponentExistingData = {
      juurilasku: rivi.juurilasku,
      kasiteltava: rivi.kasiteltava
    }
    this.laskuKatseleComponentDataResolve.asetaOlemassaolevaData(existing)
    const nykyinenAsiakas = await firstValueFrom(this.asiakasService.nykyinenAsiakasAvainObservable)
    this.router.navigate(['asiakkaat', nykyinenAsiakas.avain, 'laskut', 'selaa', rivi.juuriAvain, rivi.avain])

  }

  // lisaa() {

  //   this.laskuService.asetuksetObservable.pipe(
  //     take(1)
  //   ).subscribe(asetukset => {
  //     if (asetukset) {
  //       if (this.asetustenOletuksetService.tarkistaPuuttuukoLaskuasetuksia(asetukset)) {
  //         this.naytaTaydennaAsetuksetDialogi()
  //       } else {
  //         // Sulje dialogi, jos sellainen oli
  //         if (this._taydennaAsetuksetDialogi) {
  //           this._taydennaAsetuksetDialogi.close()
  //           this._taydennaAsetuksetDialogi = null
  //         }
  //         this.router.navigate(['/laskutus/laskut/', 'uusi', 'uusi'])
  //       }
  //     }
  //   })

  // }

  // private naytaTaydennaAsetuksetDialogi() {
  //   if (!this._taydennaAsetuksetDialogi) {
  //     const kaytettavaLeveys = window.innerWidth
  //     const kaytettavaKorkeus = window.innerHeight
  //     const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
  //     const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 750 ? '85vh' : '92vh'

  //     const settings: MatDialogConfig = {
  //       maxWidth: '900px',
  //       maxHeight: korkeus,
  //       width: leveys,
  //       data: {
  //         mobiili: kaytettavaLeveys < 670
  //       }
  //     }

  //     this._taydennaAsetuksetDialogi = this.dialog.open(LaskuTaydennaAsetuksetDialog, settings)
  //     this._taydennaAsetuksetDialogi.afterClosed().subscribe(result => {
  //       this._taydennaAsetuksetDialogi = null
  //       if (result === 'onnistui') {
  //         this.router.navigate(['/laskutus/laskut/', 'uusi', 'uusi'])
  //       }
  //     })
  //   }
  // }

  // async merkitseMaksetuksi(rivi: LaskunListaustietorivi) {
  //   if (this.vertaaEkaPienempiKuinToka(0, rivi.avoinna)) {
  //     await this.laskuService.merkitseLaskuTaysinMaksetuksi(rivi)
  //   } else {
  //     const data: LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialogData = {
  //       rivi: rivi
  //     }
  //     const settings: MatDialogConfig = {
  //       data: data,
  //       panelClass: 'ilman-paddingia',
  //       maxWidth: '1600px'
  //       // ,
  //       // maxHeight: korkeus,
  //       // width: leveys
  //     }
  //     this._pauser.next(false)
  //     this.dialog.open(LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog, settings)
  //       .afterClosed()
  //       .subscribe(() => {
  //         this._pauser.next(true)
  //       })
  //   }
  // }

  vertaaYhtaSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) === Math.round(kaksi * 100)
  }

  vertaaEriSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) !== Math.round(kaksi * 100)
  }

  vertaaEkaPienempiKuinToka(pienempi: number, suurempi: number): boolean {
    return Math.round(pienempi * 100) < Math.round(suurempi * 100)
  }

  // modifyComment(lasku: Lasku) {

  //   const kaytettavaLeveys = window.innerWidth
  //   const kaytettavaKorkeus = window.innerHeight
  //   const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
  //   const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 750 ? '85vh' : '92vh'

  //   const settings: MatDialogConfig<LaskuKommenttiDialogData> = {
  //     maxWidth: '900px',
  //     maxHeight: korkeus,
  //     width: leveys,
  //     data: { lasku: lasku }
  //   }
  //   if (this._kommenttiDialogi) {
  //     return
  //   }
  //   this._kommenttiDialogi = this.dialog.open(LaskuKommenttiDialog, settings)
  //   this._kommenttiDialogi.afterClosed().subscribe(result => {
  //     this._kommenttiDialogi = null
  //     if (result === 'onnistui') { /* NOOP */ }
  //   })

  // }


}

export interface AsikkaanLaskusummatValuutalleAnimointejaVarten {
  avoinna: number
  eraantynyt: number
  avoinnaAlkuperainen: number
  eraantynytAlkuperainen: number
  valuutta: string
  valuuttaAlkuperainen: string
}

@Injectable()
export class LaskuSummatService {

  private summat: AsikkaanLaskusummatValuutalleAnimointejaVarten = {
    avoinna: 0,
    eraantynyt: 0,
    avoinnaAlkuperainen: 0,
    eraantynytAlkuperainen: 0,
    valuutta: 'EUR',
    valuuttaAlkuperainen: 'EUR'
  }

  private summatSubject = new BehaviorSubject<AsikkaanLaskusummatValuutalleAnimointejaVarten>(this.summat)
  summatObservable = this.summatSubject.asObservable()

  constructor(
    private errorHandler: ErrorHandler,
    private lemonaidFirebase: FirebaseLemonaid,
    private asiakasService: AsiakasService
  ) {

    this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {

        if (asiakas) {
          const uri = 'laskut/summat-new/' + asiakas.avain
          return this.lemonaidFirebase.databaseListenValue<LaskuTotalsPerCurrency>(uri).pipe(
            map(totals => {
              const suurin: { avoinna: number, eraantynyt: number, valuutta: string } = {
                avoinna: 0,
                eraantynyt: 0,
                valuutta: 'EUR'
              }
              if (totals && Object.keys(totals).length > 0) {
                for (const key of Object.keys(totals)) {
                  const value = totals[key]
                  if (value && suurin.avoinna + suurin.eraantynyt < value.avoinna + value.eraantynyt) {
                    suurin.avoinna = value.avoinna
                    suurin.eraantynyt = value.eraantynyt
                    suurin.valuutta = key
                  }
                }
              }
              if (suurin) {
                this.summat.avoinna = suurin.avoinna
                this.summat.eraantynyt = suurin.eraantynyt
                this.summat.valuutta = suurin.valuutta
              }
              return this.summat
            })
          )
        }

        // Ei käyttäjää, nollaa kaikki
        this.summat.avoinna = 0
        this.summat.eraantynyt = 0
        this.summat.avoinnaAlkuperainen = 0
        this.summat.eraantynytAlkuperainen = 0
        this.summat.valuutta = 'EUR'
        this.summat.valuuttaAlkuperainen = 'EUR'

        return of(this.summat)
      })
    ).subscribe(data => {
      this.summatSubject.next(data)
    })

  }

  public numerotRullattiin() {
    if (
      this.summat.avoinnaAlkuperainen !== this.summat.avoinna ||
      this.summat.eraantynytAlkuperainen !== this.summat.eraantynyt ||
      this.summat.valuuttaAlkuperainen !== this.summat.valuutta
    ) {
      this.summat.avoinnaAlkuperainen = this.summat.avoinna
      this.summat.eraantynytAlkuperainen = this.summat.eraantynyt
      this.summat.valuuttaAlkuperainen = this.summat.valuutta
      this.summatSubject.next(this.summat)
    }
  }

}

