import { Kirjaus, Kirjausrivi } from '../model/kirjanpito'

export class KirjanpitoReskontraUriService {

  getVientiAvainSeparator() {
    return '<||||>'
  }
  createVientiAvain(kirjausAvain: Kirjaus['avain'], kirjausriviAvain: Kirjausrivi['av']) {
    return kirjausAvain + this.getVientiAvainSeparator() + kirjausriviAvain
  }

  getReskontraViennitCollectionName() {
    return '/kirjanpito-reskontra-viennit'
  }

  getReskontraViennitCollection(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-reskontra-viennit'
  }
  getReskontranVientiUri(asiakasAvain: string, vientiAvain: string) {
    return this.getReskontraViennitCollection(asiakasAvain) + '/' + vientiAvain
  }
  getReskontranVientHistoriaUri(asiakasAvain: string, vientiAvain: string, historiaAvain: string) {
    return this.getReskontranVientiUri(asiakasAvain, vientiAvain) + '/kirjanpito-reskontra-vienti-historia/' + historiaAvain
  }

  getReskontraPaivitaKirjauksetTyojonoUri(asiakasAvain: string, tyojonoId: string) {
    return 'tyojono/' + asiakasAvain + '/reskontra-paivita-kirjaukset/' + tyojonoId
  }

  getReskontraLemonaidiinTyojonoUri(asiakasAvain: string, tyojonoId: string) {
    return 'tyojono/' + asiakasAvain + '/reskontra-lemonaidiin/' + tyojonoId
  }

  getReskontraLemonaidiinQueuedTyojonoDataCollection() {
    return 'tyojono_data/reskontra/reskontra-lemonaidiin-queue'
  }
  getReskontraLemonaidiinQueuedTyojonoDataUri(asiakasAvain: string) {
    return this.getReskontraLemonaidiinQueuedTyojonoDataCollection() + '/' + asiakasAvain
  }
}
