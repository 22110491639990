import { Component, OnInit, OnDestroy, ErrorHandler, ViewChild, ElementRef, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'
import { UntypedFormGroup, FormGroup, FormControl } from '@angular/forms'

import { DateAdapter } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Platform } from '@angular/cdk/platform'

import { KirjautunutKayttajaService } from '../_angular/service/kirjautunut-kayttaja.service'
import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'

import { KirjanpitajanRooli } from '../_jaettu/lemonator/model/kirjanpitaja'
import { AnnaAsiakkaidenTilastotiedotKirjanpitajittain, AnnaAsiakkaidenTilastotiedotKirjanpitajittainPyynto, AnnaAsiakkaidenTilastotiedotKirjanpitajittainVastaus, AnnaAsiakkaidenTilastotiedotPyynto, AnnaAsiakkaidenTilastotiedotVastaus, AnnaKuukaudenLaskutusJakaumallaPyynto, AnnaKuukaudenLaskutusJakaumallaVastaus, AnnaKuukaudenLaskutusKirjanpitajittainJakaumallaPyynto, AnnaKuukaudenLaskutusKirjanpitajittainJakaumallaVastaus, AnnaLaskutusmuutosKuukausittainPyynto, AnnaLaskutusmuutosKuukausittainVastaus, AsiakkaanTilastotiedot, HinnanMuutos, KuukaudenLaskutusJakaumallaTiedot, KuukaudenLaskutusKirjanpitajittainJakaumallaTiedot } from 'app/_jaettu-lemonator/model/tilastot'

import { CurrencyService } from '../_shared-core/service/currency.service'

import { Subject, Observable, BehaviorSubject, combineLatest, of as observableOf, EMPTY } from 'rxjs'
import { takeUntil, map, switchMap, distinctUntilChanged, startWith } from 'rxjs/operators'


import { KirjanpitajaService } from '../_angular/service/kirjanpitaja/kirjanpitaja.service'

import { DateService } from 'app/_shared-core/service/date.service'
import { LemonKuukausiDateAdapter } from 'app/_jaettu-angular/_material/LemonKuukausiDateAdapter'

import { Chart, PieController, LineController, BarController, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Tooltip, Legend } from 'chart.js'
import { KirjanpitajanNimitiedot, KirjanpitajanTiimi } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { LocalDate, KuukausiAikavali, LocalMonth } from 'app/_shared-core/model/common'
import { LaskuSharedService } from 'app/_jaettu/service/lasku/lasku-shared.service'
import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { VersionTarkistusPalvelu } from 'app/_angular/service/version-tarkistus.palvelu'
import { AsiakasKopioija } from 'app/_angular/service/asiakas/asiakas.kopioija'
import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { KirjanpitajanTiimiService } from '../_jaettu-lemonator/service/kirjanpitajan-tiimi.service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { AsiakkaanSopimuskaudenTila } from 'app/_jaettu-lemonator/model/asiakas'

Chart.register(PieController, LineController, BarController, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Tooltip, Legend)

interface AsiakasInfo {
  asiakkaita: number
  asiakkaitaJoillaHinta: number
  yhtiomuotojakauma: { nimi: string, maara: number, keskimaarainenHinta: number }[]
  keskimaarainenhinta: number
}

interface KuukausittainenLaskutusData {
  kuukausi: LocalMonth
  laskuista: {
    kerta: number
    jatkuva: number
    hyvityksetKerta: number
    hyvityksetJatkuva: number
  }
  hinnastosta: {
    lisays: number
    poistuma: number
    laskutus: number
  }
}

interface KuukausittaisenTablenRivi {
  bold: boolean
  cols: string[]
}

interface AsiakkaanHinnanMuutokset { asiakas: AsiakkaanTilastotiedot, kirjanpitaja: KirjanpitajanNimitiedot, hinnat: HinnanMuutos[] }

interface KirjanpitajatFormGroup {
  vuosikkalku: FormControl<Date>
}

@Component({
  templateUrl: './tilastot.component.html',
  styleUrls: ['./tilastot.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] }
  ]
})
export class TilastotComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSort) _sort: MatSort
  @ViewChild('kuukausittainCanvas', { static: true }) kuukausittainCanvasRef: ElementRef
  @ViewChild('kuukausittainenMuutosCanvas', { static: true }) kuukausittainenMuutosCanvasRef: ElementRef
  @ViewChild('kuukausittainKirjanpitajittainCanvas') kuukausittainKirjanpitajittainCanvasRef: ElementRef
  @ViewChild('yhtiojakaumaCanvas', { static: true }) yhtiojakaumaCanvas: ElementRef

  kuukausittainCanvasDataOnLataamatta = true
  kuukausittainenMuutosCanvasOnLataamatta = true
  kuukausittainKirjanpitajittainCanvasOnLataamatta = true
  kirjanpitajittainTableLataa = true

  kirjanpitajittainTableYhteensa = {
    asiakkaita: 0,
    jatkuvakk1: 0,
    jatkuvakk2: 0,
    lisayksia: 0,
    poistuma: 0,
    keskim: 0,
    oy: 0,
    tmi: 0,
    muut: 0
  }


  kirjanpitajatDataSource: MatTableDataSource<AnnaAsiakkaidenTilastotiedotKirjanpitajittain> = new MatTableDataSource([])

  private varit: string[] = [
    'rgba(184, 231, 166, 1)',
    'rgba(242, 167, 180, 1)',
    'rgba(153, 196, 231, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(231, 233, 237, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(151, 187, 205, 1)',
    'rgba(220, 220, 220, 1)',
    'rgba(247, 70, 74, 1)',
    'rgba(70, 191, 189, 1)',
    'rgba(253, 180, 92, 1)',
    'rgba(148, 159, 177, 1)',
    'rgba(77, 83, 96, 1)'
  ]

  tilastotForm: UntypedFormGroup
  kirjanpitajatForm: FormGroup<KirjanpitajatFormGroup>
  private _valittuAikavaliSubject: BehaviorSubject<KuukausiAikavali> = new BehaviorSubject(null)
  private _valittuAikavaliObservable: Observable<KuukausiAikavali> = this._valittuAikavaliSubject.asObservable()
  // .pipe(
  //   distinctUntilChanged((prev, next) => {
  //     if (
  //       prev.start.year !== next.start.year ||
  //       prev.end.year !== next.end.year ||
  //       prev.start.month !== next.start.month ||
  //       prev.end.month !== next.end.month
  //     ) {
  //       return false
  //     }
  //     return true
  //   })
  // )
  private _valittuKirjanpitajienAikavaliSubject: BehaviorSubject<KuukausiAikavali> = new BehaviorSubject(null)
  valittuKirjanpitajienKk1Observable = this._valittuKirjanpitajienAikavaliSubject.pipe(
    map(aikavali => {
      if (aikavali?.start) {
        return '1.' + aikavali.start.month
      }
    })
  )
  valittuKirjanpitajienKk2Observable = this._valittuKirjanpitajienAikavaliSubject.pipe(
    map(aikavali => {
      if (aikavali?.start) {
        const next = this._dateService.lisaaKuukausiaLocalMonth(aikavali.start, 1)
        return '1.' + next.month
      }
    })
  )
  purettuAikavaliPlusKuukausiMolempiinPaihinObservable: Observable<LocalMonth[]>
  kuukausittainenJakaumaObservable: Observable<KuukaudenLaskutusJakaumallaTiedot[]>
  kirjanpitajittainJakaumaObservable: Observable<KuukaudenLaskutusKirjanpitajittainJakaumallaTiedot[]>
  // kirjanpitajatTablenTiedotObservable: Observable<KirjanpitajatTablenTiedot[]>
  kirjanpitajienNimitiedotObservable: Observable<KirjanpitajanNimitiedot[]>
  tiimitObservable: Observable<KirjanpitajanTiimi[]>
  kuukausittaisenDatanRivitObservable: Observable<KuukausittaisenTablenRivi[]>

  asiakkaidenKuukausihintojenMuutoksetObservable: Observable<AsiakkaanHinnanMuutokset[]>
  asiakkaidenKuukausihintojenSummatObservable: Observable<{ localMonth: LocalMonth, poistuma: number, lisays: number, summa: number }[]>

  asiakasInfoObservable: Observable<AsiakasInfo>
  asiakkaatObservable: Observable<AsiakkaanTilastotiedot[]>

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  // private kolmenViimeisenKuunLaskutObservable: Observable<Lasku[]>
  // duplikaattiLaskutObservable: Observable<DuplikaattiLasku[]>
  // duplikaatitLataavatObservable: Observable<boolean>

  naytaSuperObservable: Observable<boolean>
  naytaSpeccerObservable: Observable<boolean>
  naytaTarkkaJakaumaFormControl: FormControl<boolean> = new FormControl(false)
  naytaTarkkaJakaumaKirjanpitajatFormControl: FormControl<boolean> = new FormControl(false)
  etsiName = 'mhgyuketer' + Math.random()

  private kuukausittainChart: Chart = null
  private kuukausittainenMuutosChart: Chart = null
  private yhtiojakaumaChart: Chart<'pie', number[], string> = null
  private kuukausittainKirjanpitajittainChart: Chart = null

  private _rajauksetSubject: BehaviorSubject<{ kirjanpitaja: string, tiimi: string }> = new BehaviorSubject({ kirjanpitaja: null, tiimi: null })

  constructor(
    private _errorHandler: ErrorHandler,
    private _router: Router,
    private _asiakasService: AsiakasService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _currencyService: CurrencyService,
    private _dateService: DateService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _laskuSharedService: LaskuSharedService,
    private _versionTarkistusPalvelu: VersionTarkistusPalvelu,
    private _asiakasKopioija: AsiakasKopioija,
    private _timestampService: TimestampService,
    private _kopioijaPalvelu: KopioijaPalvelu,
    private _kirjanpitajanTiimiService: KirjanpitajanTiimiService
  ) {

    this.tiimitObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(k => {
        return this._kirjanpitajanTiimiService.annaKaikkiTiimit()
      })
    )

    this.kirjanpitajienNimitiedotObservable = combineLatest([this._kirjanpitajaService.kirjanpitajienNimitiedotObservable, this._rajauksetSubject]).pipe(
      switchMap(async ([kirjanpitajat, rajaukset]) => {
        if (kirjanpitajat?.length) {
          const sorted = kirjanpitajat.sort((a, b) => {
            if (a.etunimi !== b.etunimi) {
              return a.etunimi.localeCompare(b.etunimi)
            }
            return a.sukunimi.localeCompare(b.sukunimi)
          })
          if (rajaukset.tiimi) {
            const tiimi = await this._kirjanpitajanTiimiService.annaTiimi(rajaukset.tiimi)
            const tanaanSet = this._kirjanpitajanTiimiService.annaTiiminKirjanpitajatPaivalleSet(tiimi, this._dateService.currentNumberDate())
            return sorted.filter(kirjanpitaja => tanaanSet.has(kirjanpitaja.avain))
          }
          return sorted
        }
        return []
      })
    )

    const nyt = new Date()
    const ensiKuussa = this._dateService.lisaaKuukausia(nyt, 1)
    const puolivuottaSitten = this._dateService.lisaaKuukausia(nyt, -2)

    this._valittuAikavaliSubject.next({
      start: {
        month: puolivuottaSitten.getMonth() + 1,
        year: puolivuottaSitten.getFullYear()
      },
      end: {
        month: ensiKuussa.getMonth() + 1,
        year: ensiKuussa.getFullYear()
      }
    })

    this.purettuAikavaliPlusKuukausiMolempiinPaihinObservable = this._valittuAikavaliObservable.pipe(
      map(aikavali => {
        const alku = this._dateService.lisaaKuukausiaPaikallinen({ year: aikavali.start.year, month: aikavali.start.month, day: 12 }, -1)
        const loppu = this._dateService.lisaaKuukausiaPaikallinen({ year: aikavali.end.year, month: aikavali.end.month, day: 12 }, 1)
        return this.puraJaSuodataAikavali({ start: alku, end: loppu }, null)
      })
    )

    this.tilastotForm = new FormGroup({
      'vuosikkalku': new FormControl(puolivuottaSitten, []),
      'vuosikkloppu': new FormControl(ensiKuussa, []),
      'kirjanpitaja': new FormControl(null, []),
      'tiimi': new FormControl(null)
    })

    this.tilastotForm.get('kirjanpitaja').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: string) => {
      if (this._rajauksetSubject.value.kirjanpitaja !== value) {
        this.kuukausittainCanvasDataOnLataamatta = true
        this.kuukausittainenMuutosCanvasOnLataamatta = true
        this.kuukausittainKirjanpitajittainCanvasOnLataamatta = true
        this.kirjanpitajittainTableLataa = true
        this._rajauksetSubject.value.kirjanpitaja = value
        this._rajauksetSubject.next(this._rajauksetSubject.value)
      }
    })

    this.tilastotForm.get('tiimi').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: string) => {
      if (this._rajauksetSubject.value.tiimi !== value) {
        this.kuukausittainCanvasDataOnLataamatta = true
        this.kuukausittainenMuutosCanvasOnLataamatta = true
        this.kuukausittainKirjanpitajittainCanvasOnLataamatta = true
        this.kirjanpitajittainTableLataa = true
        this._rajauksetSubject.value.tiimi = value
        this._rajauksetSubject.value.kirjanpitaja = null
        this._rajauksetSubject.next(this._rajauksetSubject.value)
      }
    })

    this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: async (kirjanpitajanTiedot) => {
        if (kirjanpitajanTiedot && !this.tilastotForm.get('tiimi').value) {
          const tiimit = await this._kirjanpitajanTiimiService.annaTiimitJotaKirjanpitajaVetaa(kirjanpitajanTiedot.uid, this._dateService.currentNumberDate())
          if (tiimit?.length > 0) {
            this.tilastotForm.get('tiimi').setValue(tiimit[0].avain)
          }
        }
      }
    })

    this.asiakkaatObservable = combineLatest([this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable, this._rajauksetSubject, this._valittuAikavaliObservable]).pipe(
      switchMap(([kirjautunutKirjanpitaja, rajaukset, aikavali]) => {

        if (!kirjautunutKirjanpitaja) {
          return observableOf([])
        }

        const pyynto: AnnaAsiakkaidenTilastotiedotPyynto = {
          rajoitettuKirjanpitajaanAvain: null,
          rajoitettuTiimiinAvain: rajaukset.tiimi ?? null
        }
        if (kirjautunutKirjanpitaja.rooli === KirjanpitajanRooli.SUPER) {
          if (rajaukset.kirjanpitaja) {
            pyynto.rajoitettuKirjanpitajaanAvain = rajaukset.kirjanpitaja
          }
          pyynto.aikavali = aikavali
        } else {
          pyynto.rajoitettuKirjanpitajaanAvain = kirjautunutKirjanpitaja.uid
        }

        return this._firebase.functionsCall<AnnaAsiakkaidenTilastotiedotPyynto, AnnaAsiakkaidenTilastotiedotVastaus>('tilastotAsiakkaidenTilastotiedot', pyynto)
          .then(nimitiedotVastaus => {
            return nimitiedotVastaus?.tiedot ?? []
          })

      }),
      lemonShare()
    )

    this.tilastotForm.get('vuosikkalku').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      if (value) {
        // console.log('asetettu vuosikkalku', value)
        const current = this._valittuAikavaliSubject.value
        const newStart: LocalMonth = { month: value.getMonth() + 1, year: value.getFullYear() }
        if (newStart.year !== current.start.year || newStart.month !== current.start.month) {
          // console.log('SET START', newStart, current.start)
          current.start = newStart
          this.kuukausittainCanvasDataOnLataamatta = true
          this.kuukausittainenMuutosCanvasOnLataamatta = true
          this.kuukausittainKirjanpitajittainCanvasOnLataamatta = true
          this._valittuAikavaliSubject.next(current)
        }
      }
    })

    this.tilastotForm.get('vuosikkloppu').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      if (value) {
        // console.log('asetettu vuosikkloppu', value)
        const current = this._valittuAikavaliSubject.value
        const newEnd: LocalMonth = { month: value.getMonth() + 1, year: value.getFullYear() }
        if (newEnd.year !== current.end.year || newEnd.month !== current.end.month) {
          // console.log('SET END', newEnd, current.end)
          current.end = newEnd
          this.kuukausittainCanvasDataOnLataamatta = true
          this.kuukausittainenMuutosCanvasOnLataamatta = true
          this.kuukausittainKirjanpitajittainCanvasOnLataamatta = true
          this._valittuAikavaliSubject.next(current)
        }
      }
    })

    this._valittuKirjanpitajienAikavaliSubject.next({
      start: {
        month: nyt.getMonth() + 1,
        year: nyt.getFullYear()
      },
      end: {
        month: ensiKuussa.getMonth() + 1,
        year: ensiKuussa.getFullYear()
      }
    })

    this.kirjanpitajatForm = new FormGroup({
      'vuosikkalku': new FormControl(nyt, []),
    })

    this.kirjanpitajatForm.get('vuosikkalku').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      if (value) {
        // console.log('asetettu vuosikkalku', value)
        this.kirjanpitajittainTableLataa = true
        const current = this._valittuKirjanpitajienAikavaliSubject.value
        current.start = { month: value.getMonth() + 1, year: value.getFullYear() }
        this._valittuKirjanpitajienAikavaliSubject.next(current)
      }
    })

  }

  private annaYhtiomuodonNimi(yritysmuoto: Yritysmuoto) {
    switch (yritysmuoto) {
      case Yritysmuoto.AVOINYHTIO:
        return 'Avoin yhtiö'
      case Yritysmuoto.KOMMANDIITTIYHTIO:
        return 'Kommandiittiyhtiö'
      case Yritysmuoto.OSAKEYHTIO:
        return 'Osakeyhtiö'
      case Yritysmuoto.OSUUSKUNTA:
        return 'Osuuskunta'
      case Yritysmuoto.TOIMINIMI:
        return 'Toiminimi'
      case Yritysmuoto.YHDISTYS:
        return 'Yhdistys'
      default:
        return 'Tuntematon yhtiömuoto'
    }
  }

  chosenMonthHandlerKirjanpitajatAlku(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.kirjanpitajatForm.get('vuosikkalku').setValue(normalizedMonth)
    datepicker.close()
  }

  chosenMonthHandlerAlku(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.tilastotForm.get('vuosikkalku').setValue(normalizedMonth)
    datepicker.close()
  }

  chosenMonthHandlerLoppu(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.tilastotForm.get('vuosikkloppu').setValue(normalizedMonth)
    datepicker.close()
  }

  // private getVuosiKkInternal(vuosikk: VuosiKk): string {
  //   if (vuosikk && vuosikk.vuosi && vuosikk.kohde && vuosikk.kk > -1) {
  //     return vuosikk.vuosi.toString().substring(2) + vuosikk.kohde + vuosikk.kk
  //   }
  //   return ''
  // }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngAfterViewInit() {
    this.kirjanpitajatDataSource.sort = this._sort
  }

  ngOnInit() {

    // this.kaikkiLaskutObservable.next(null)

    // const tanaan = this._dateService.dateToLocalDate(new Date())
    // const kuukausiSitten = this._dateService.lisaaKuukausiaPaikallinen(tanaan, -1)
    // const kaksiKuukauttaSitten = this._dateService.lisaaKuukausiaPaikallinen(tanaan, -2)
    // const kolmeKuukauttaSitten = this._dateService.lisaaKuukausiaPaikallinen(tanaan, -3)

    // const sorttaus = LaskunSorttaukset.ASIAKAS
    // const tilaS = '' // (tila && tila !== LaskunTila.kaikki) ? tila : ''
    // const vuosikkS1 = this.getVuosiKkInternal({ kk: kuukausiSitten.month, vuosi: kuukausiSitten.year, kohde: 'p' })
    // const vuosikkS3 = this.getVuosiKkInternal({ kk: kaksiKuukauttaSitten.month, vuosi: kaksiKuukauttaSitten.year, kohde: 'p' })
    // const vuosikkS2 = this.getVuosiKkInternal({ kk: kolmeKuukauttaSitten.month, vuosi: kolmeKuukauttaSitten.year, kohde: 'p' })
    // const vapaahakuS = '' // hakukriteerit.vapaahaku ? hakukriteerit.vapaahaku : ''
    // const hakuavain1 = sorttaus.tunniste + tilaS + vuosikkS1 + vapaahakuS
    // const hakuavain2 = sorttaus.tunniste + tilaS + vuosikkS2 + vapaahakuS
    // const hakuavain3 = sorttaus.tunniste + tilaS + vuosikkS3 + vapaahakuS
    // const searchProperty1 = 'haku.' + hakuavain1
    // const searchProperty2 = 'haku.' + hakuavain2
    // const searchProperty3 = 'haku.' + hakuavain3

    // const kk1Observable = this._firebaseLemonaid.firestoreCollection<Lasku>('/laskut/9/laskut').whereFree(searchProperty1, '>', '').orderByFree(searchProperty1, 'asc').listen()
    // const kk2Observable = this._firebaseLemonaid.firestoreCollection<Lasku>('/laskut/9/laskut').whereFree(searchProperty2, '>', '').orderByFree(searchProperty2, 'asc').listen()
    // const kk3Observable = this._firebaseLemonaid.firestoreCollection<Lasku>('/laskut/9/laskut').whereFree(searchProperty3, '>', '').orderByFree(searchProperty3, 'asc').listen()

    // this.kolmenViimeisenKuunLaskutObservable = combineLatest([kk1Observable, kk2Observable, kk3Observable]).pipe(
    //   map(kaikki => {
    //     kaikki[0].map(lasku => { delete lasku['haku'] })
    //     kaikki[1].map(lasku => { delete lasku['haku'] })
    //     kaikki[2].map(lasku => { delete lasku['haku'] })
    //     return [].concat(kaikki[0]).concat(kaikki[1]).concat(kaikki[2])
    //   })
    // )

    // this.duplikaattiLaskutObservable = this.kolmenViimeisenKuunLaskutObservable.pipe(
    //   map(laskut => {
    //     const duplikaatit: DuplikaattiLasku[] = []

    //     const m: { [key: string]: DuplikaattiLasku } = {}
    //     for (const lasku of laskut) {
    //       const avain = lasku.pvml.year + '_' + lasku.pvml.month + '_' + lasku.pvml.day + '_' + this._currencyService.formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(lasku.summa, 'fi') + '_' + lasku.asiakas.avain
    //       if (m[avain]) {
    //         m[avain].lasku2 = lasku
    //         m[avain].laskunro2 = this._laskuSharedService.annaMuotoiltuLaskunumero(lasku, lasku)
    //       } else {
    //         m[avain] = { asiakas: lasku.asiakas, erapvm: lasku.erapvml, pvm: lasku.pvml, lasku1: lasku, lasku2: null, laskunro1: this._laskuSharedService.annaMuotoiltuLaskunumero(lasku, lasku), laskunro2: null }
    //       }
    //     }

    //     for (const avain of Object.keys(m)) {
    //       const duplikaatti = m[avain]
    //       if (duplikaatti.lasku1 && duplikaatti.lasku2) {
    //         duplikaatit.push(duplikaatti)
    //       }
    //     }

    //     return duplikaatit
    //   }),
    //   lemonShare()
    // )

    // this.duplikaatitLataavatObservable = this.duplikaattiLaskutObservable.pipe(
    //   map(duplikaatit => {
    //     return false
    //   }),
    //   startWith(true)
    // )

    this.kirjanpitajatDataSource.sortData = (data: AnnaAsiakkaidenTilastotiedotKirjanpitajittain[], sort: MatSort): AnnaAsiakkaidenTilastotiedotKirjanpitajittain[] => {

      const active = sort.active
      const direction = sort.direction

      // console.log('SORT', sort)

      if (!active || direction === '') { return data }

      const directionMultiplier = direction === 'asc' ? 1 : -1

      if (active === 'asiakkaita') {
        return data.sort((a, b) => {
          if (a.asiakkaita === null && b.asiakkaita === null) {
            return 0
          } else if (a.asiakkaita === null) {
            return 1 * directionMultiplier
          } else if (b.asiakkaita === null) {
            return -1 * directionMultiplier
          }
          return (a.asiakkaita - b.asiakkaita) * directionMultiplier
        })
      }

      if (active === 'keskimveloitus') {
        return data.sort((a, b) => {
          if (a.keskimaarainenVeloitus === null && b.keskimaarainenVeloitus === null) {
            return 0
          } else if (a.keskimaarainenVeloitus === null) {
            return 1 * directionMultiplier
          } else if (b.keskimaarainenVeloitus === null) {
            return -1 * directionMultiplier
          }
          return (a.keskimaarainenVeloitus - b.keskimaarainenVeloitus) * directionMultiplier
        })
      }

      if (active === 'jatkuvakk1') {
        return data.sort((a, b) => {
          if (a.jatkuvakk1 === null && b.jatkuvakk1 === null) {
            return 0
          } else if (a.jatkuvakk1 === null) {
            return 1 * directionMultiplier
          } else if (b.jatkuvakk1 === null) {
            return -1 * directionMultiplier
          }
          return (a.jatkuvakk1 - b.jatkuvakk1) * directionMultiplier
        })
      }

      if (active === 'jatkuvakk2') {
        return data.sort((a, b) => {
          if (a.jatkuvakk2 === null && b.jatkuvakk2 === null) {
            return 0
          } else if (a.jatkuvakk2 === null) {
            return 1 * directionMultiplier
          } else if (b.jatkuvakk2 === null) {
            return -1 * directionMultiplier
          }
          return (a.jatkuvakk2 - b.jatkuvakk2) * directionMultiplier
        })
      }

      if (active === 'lisaykset') {
        return data.sort((a, b) => {
          if (a.lisaykset === null && b.lisaykset === null) {
            return 0
          } else if (a.lisaykset === null) {
            return 1 * directionMultiplier
          } else if (b.lisaykset === null) {
            return -1 * directionMultiplier
          }
          return (a.lisaykset - b.lisaykset) * directionMultiplier
        })
      }

      if (active === 'vahennykset') {
        return data.sort((a, b) => {
          if (a.vahennykset === null && b.vahennykset === null) {
            return 0
          } else if (a.vahennykset === null) {
            return 1 * directionMultiplier
          } else if (b.vahennykset === null) {
            return -1 * directionMultiplier
          }
          return (a.vahennykset - b.vahennykset) * directionMultiplier
        })
      }

      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
      if (active === 'yhtiomuodot') {
        return data.sort((a, b) => {
          if (a.yhtiomuodotTmi !== b.yhtiomuodotTmi) {
            return (a.yhtiomuodotTmi - b.yhtiomuodotTmi) * directionMultiplier
          } else if (a.yhtiomuodotOy !== b.yhtiomuodotOy) {
            return (a.yhtiomuodotOy - b.yhtiomuodotOy) * directionMultiplier
          } else if (a.yhtiomuodotMuut !== b.yhtiomuodotMuut) {
            return (a.yhtiomuodotMuut - b.yhtiomuodotMuut) * directionMultiplier
          }
          return 0
        })
      }

      if (active === 'kirjanpitaja') {
        return data.sort((a, b) => {
          return collator.compare(a.kirjanpitaja, b.kirjanpitaja) * directionMultiplier
        })
      }

      return data

    }

    this.asiakasInfoObservable = this.asiakkaatObservable.pipe(
      map(asiakkaat => {
        const paluuarvo: AsiakasInfo = {
          asiakkaita: 0,
          asiakkaitaJoillaHinta: 0,
          keskimaarainenhinta: 0,
          yhtiomuotojakauma: []
        }
        const yhtiojakaumaMap: Map<string, { hinta: number, lukumaara: number }> = new Map()
        let hintaYhteensa = 0
        for (const asiakas of asiakkaat) {
          // if (
          //   asiakas.k === 'uekRGWgeLqecXk0Gh8el0s85SEt1' ||
          //   asiakas.q ||
          //   asiakas.s === AsiakkaanSopimuskaudenTila.PAATTYNYT ||
          //   asiakas.s === AsiakkaanSopimuskaudenTila.POYTALAATIKOSSA ||
          //   asiakas.s === AsiakkaanSopimuskaudenTila.PURETTU ||
          //   asiakas.s === AsiakkaanSopimuskaudenTila.TAUOLLA ||
          //   asiakas.s === AsiakkaanSopimuskaudenTila.PAATTYMASSA
          // ) {
          //   continue
          // }
          paluuarvo.asiakkaita++
          if (asiakas.h1) {
            hintaYhteensa += asiakas.h1
            paluuarvo.asiakkaitaJoillaHinta++
            const item = yhtiojakaumaMap.get(asiakas.y)
            if (item) {
              item.hinta += asiakas.h1
              item.lukumaara++
            } else {
              yhtiojakaumaMap.set(asiakas.y, {
                hinta: asiakas.h1,
                lukumaara: 1
              })
            }
          }
        }

        if (paluuarvo.asiakkaitaJoillaHinta > 0) {
          paluuarvo.keskimaarainenhinta = hintaYhteensa / paluuarvo.asiakkaitaJoillaHinta
        }

        for (const [jakaumaAvain, jakaumatieto] of yhtiojakaumaMap.entries()) {
          paluuarvo.yhtiomuotojakauma.push({
            keskimaarainenHinta: jakaumatieto.lukumaara > 0 ? jakaumatieto.hinta / jakaumatieto.lukumaara : 0,
            maara: jakaumatieto.lukumaara,
            nimi: this.annaYhtiomuodonNimi(jakaumaAvain as Yritysmuoto)
          })
        }

        paluuarvo.yhtiomuotojakauma = paluuarvo.yhtiomuotojakauma.sort((a, b) => {
          return a.nimi.localeCompare(b.nimi)
        })

        return paluuarvo
      })
    )

    this.asiakkaidenKuukausihintojenMuutoksetObservable = combineLatest([this.asiakkaatObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([asiakkaat, kirjanpitajatMap]) => {
        const paluuarvo: AsiakkaanHinnanMuutokset[] = []

        for (const asiakas of asiakkaat) {

          if (asiakas.hm) {
            const asiakkaanPaluuarvo: AsiakkaanHinnanMuutokset = {
              asiakas: asiakas,
              hinnat: asiakas.hm,
              kirjanpitaja: kirjanpitajatMap.get(asiakas.k)
            }
            paluuarvo.push(asiakkaanPaluuarvo)
          }

        }

        paluuarvo.sort((a, b) => (a.asiakas.n ?? '').localeCompare((b.asiakas.n ?? '')))

        return paluuarvo
      }),
      lemonShare()
    )

    this.asiakkaidenKuukausihintojenSummatObservable = this.asiakkaidenKuukausihintojenMuutoksetObservable.pipe(
      map(muutokset => {
        const summat: { localMonth: LocalMonth, poistuma: number, lisays: number, summa: number }[] = []
        if (muutokset && muutokset.length > 0) {
          const length = muutokset[0].hinnat.length
          for (let i = 0; i < length; i++) {
            summat[i] = { localMonth: muutokset[0].hinnat[i].localMonth, summa: 0, lisays: 0, poistuma: 0 }
          }
        }
        for (const asiakkaanHinnanMuutokset of muutokset) {
          const length = asiakkaanHinnanMuutokset.hinnat.length
          for (let i = 0; i < length; i++) {
            const muutos = asiakkaanHinnanMuutokset.hinnat[i].muutos || 0
            summat[i].summa += muutos
            if (muutos < 0) {
              summat[i].poistuma += muutos
            } else {
              summat[i].lisays += muutos
            }
          }
        }
        for (const summa of summat) {
          summa.lisays = summa.lisays === 0 ? null : summa.lisays
          summa.poistuma = summa.poistuma === 0 ? null : summa.poistuma
          summa.summa = summa.summa === 0 ? null : summa.summa
        }
        return summat
      }),
      lemonShare()
    )

    const laskutuksenMuutosKuukausittainObservable = combineLatest([this._valittuAikavaliObservable, this._rajauksetSubject]).pipe(
      switchMap(([aikavali, rajaukset]) => {
        const payload: AnnaLaskutusmuutosKuukausittainPyynto = {
          aikavali: aikavali,
          rajoitettuKirjanpitajaanAvain: rajaukset.kirjanpitaja ?? null,
          rajoitettuTiimiinAvain: rajaukset.tiimi ?? null
        }
        return this._firebase.functionsCall<AnnaLaskutusmuutosKuukausittainPyynto, AnnaLaskutusmuutosKuukausittainVastaus>('tilastotLaskutusKuukausittainMuutos', payload).then(vastaus => {
          return vastaus.muutosdata
        })
      }),
      lemonShare()
    )

    this.kuukausittainenJakaumaObservable = combineLatest([
      this._valittuAikavaliObservable, // .pipe(tap((t) => console.log('_valittuAikavaliObservable changed', t))),
      this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable, // .pipe(tap((t) => console.log('kirjanpitajanTiedotObservable changed', t))),
      this._rajauksetSubject, // .pipe(tap((t) => console.log('_rajauksetSubject changed', t)))
      this.naytaTarkkaJakaumaFormControl.valueChanges.pipe(startWith(this.naytaTarkkaJakaumaFormControl.value))
    ]).pipe(
      switchMap(([aikavali, kirjanpitajanTiedot, rajaukset, tarkkajakauma]) => {

        if (aikavali && kirjanpitajanTiedot) {

          const pyynto: AnnaKuukaudenLaskutusJakaumallaPyynto = {
            aikavali: aikavali,
            palautaLahteet: tarkkajakauma,
            rajoitettuKirjanpitajaanAvain: kirjanpitajanTiedot.uid,
            rajoitettuTiimiinAvain: rajaukset.tiimi ?? null
          }

          if (kirjanpitajanTiedot.rooli === KirjanpitajanRooli.SUPER) {
            if (rajaukset.kirjanpitaja) {
              pyynto.rajoitettuKirjanpitajaanAvain = rajaukset.kirjanpitaja
            } else {
              pyynto.rajoitettuKirjanpitajaanAvain = 'kaikki'
            }
          }

          return this._firebase.functionsCall<AnnaKuukaudenLaskutusJakaumallaPyynto, AnnaKuukaudenLaskutusJakaumallaVastaus>('tilastotLaskutusKuukausittain', pyynto)
            .then(vastaus => {
              return vastaus?.jakaumadata ?? []
            })

        }

        return observableOf<KuukaudenLaskutusJakaumallaTiedot[]>([])

      }),
      lemonShare()
    )

    // const viimeinen30PvRaakadataObservable = combineLatest([this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable, this._rajauksetSubject]).pipe(
    //   switchMap(([kirjanpitajanTiedot, rajaukset]) => {
    //     if (kirjanpitajanTiedot?.rooli === KirjanpitajanRooli.SUPER) {
    //       const end = this._dateService.dateToLocalDate(new Date())
    //       const start = this._dateService.lisaaPaiviaPaikallinen(end, -30)
    //       const pyynto: AnnaKuukaudenLaskutusKirjanpitajittainJakaumallaPyynto = {
    //         aikavali: {
    //           end: end,
    //           start: start
    //         },
    //         palautaLahteet: false,
    //         rajoitettuKirjanpitajaanAvain: null,
    //         rajoitettuTiimiinAvain: rajaukset.tiimi ?? null
    //       }

    //       return this._firebase.functionsCall<AnnaKuukaudenLaskutusKirjanpitajittainJakaumallaPyynto, AnnaKuukaudenLaskutusKirjanpitajittainJakaumallaVastaus>('tilastotLaskutusKirjanpitajittainKuukausittain', pyynto)
    //         .then(vastaus => {
    //           return vastaus?.jakaumadata ?? []
    //         })
    //     }
    //     return observableOf<KuukaudenLaskutusKirjanpitajittainJakaumallaTiedot[]>([])
    //   })
    // )

    const kirjanpitajittainDataObservable = combineLatest([
      this._valittuAikavaliObservable,
      this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable,
      this._rajauksetSubject,
      this.naytaTarkkaJakaumaKirjanpitajatFormControl.valueChanges.pipe(startWith(this.naytaTarkkaJakaumaKirjanpitajatFormControl.value))
    ]).pipe(
      switchMap(([aikavali, kirjanpitajanTiedot, rajaukset, tarkkajakauma]) => {

        if (aikavali && kirjanpitajanTiedot) {

          const pyynto: AnnaKuukaudenLaskutusKirjanpitajittainJakaumallaPyynto = {
            aikavali: aikavali,
            palautaLahteet: tarkkajakauma,
            rajoitettuKirjanpitajaanAvain: kirjanpitajanTiedot.uid,
            rajoitettuTiimiinAvain: rajaukset.tiimi ?? null
          }

          if (kirjanpitajanTiedot.rooli === KirjanpitajanRooli.SUPER) {
            if (rajaukset.kirjanpitaja) {
              pyynto.rajoitettuKirjanpitajaanAvain = rajaukset.kirjanpitaja
            } else {
              pyynto.rajoitettuKirjanpitajaanAvain = 'kaikki'
            }
          }

          return this._firebase.functionsCall<AnnaKuukaudenLaskutusKirjanpitajittainJakaumallaPyynto, AnnaKuukaudenLaskutusKirjanpitajittainJakaumallaVastaus>('tilastotLaskutusKirjanpitajittainKuukausittain', pyynto)
            .then(vastaus => {
              return vastaus?.jakaumadata ?? []
            })

        }

        return observableOf<KuukaudenLaskutusKirjanpitajittainJakaumallaTiedot[]>([])

      })
    )

    this.kirjanpitajittainJakaumaObservable = combineLatest([this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable, kirjanpitajittainDataObservable]).pipe(
      map(([kirjanpitajatMap, tiedot]) => {

        for (const kuukaudenTiedot of tiedot) {
          const kirjanpitaja = kirjanpitajatMap.get(kuukaudenTiedot.kirjanpitajanAvain)
          if (kirjanpitaja) {
            kuukaudenTiedot.kirjanpitaja = kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi
          }
        }

        return tiedot.sort((a, b) => {
          if (a.kirjanpitaja !== b.kirjanpitaja) {
            return a.kirjanpitaja.localeCompare(b.kirjanpitaja)
          }
          if (a.vuosi !== b.vuosi) {
            return a.vuosi - b.vuosi
          }
          return a.kuukausi - b.kuukausi
        })

      })
    )

    // combineLatest([viimeinen30PvRaakadataObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotObservable, this.asiakkaatObservable, this._rajauksetSubject]).pipe(
    //   switchMap(async ([laskutusViim30pv, kirjanpitajat, asiakkaat, rajaukset]) => {

    //     const tiimi = rajaukset.tiimi ? await this._kirjanpitajanTiimiService.annaTiimi(rajaukset.tiimi) : null
    //     const tiimiSet = tiimi ? this._kirjanpitajanTiimiService.annaTiiminKirjanpitajatPaivalleSet(tiimi, this._dateService.currentNumberDate()) : null
    //     const paluuarvo: KirjanpitajatTablenTiedot[] = []
    //     for (const kirjanpitaja of kirjanpitajat) {

    //       if (tiimiSet && !tiimiSet.has(kirjanpitaja.avain)) {
    //         continue
    //       }

    //       let kertalaskutus = 0
    //       for (const raakadata of laskutusViim30pv) {
    //         if (raakadata.kirjanpitajanAvain === kirjanpitaja.avain) {
    //           kertalaskutus += raakadata.kertalaskutus
    //         }
    //       }

    //       let voimassaolevat = 0
    //       let muutosTulevaisuudessa = 0
    //       let asiakkaita = 0
    //       let toiminimia = 0
    //       let osakeyhtioita = 0
    //       let muitaYrityksia = 0
    //       for (const asiakas of asiakkaat) {
    //         if (asiakas.k === kirjanpitaja.avain) {
    //           if (asiakas.h1) {
    //             asiakkaita++
    //             voimassaolevat += asiakas.h1
    //             if (asiakas.y === Yritysmuoto.OSAKEYHTIO) {
    //               osakeyhtioita++
    //             } else if (asiakas.y === Yritysmuoto.TOIMINIMI) {
    //               toiminimia++
    //             } else {
    //               muitaYrityksia++
    //             }
    //           }
    //           if (asiakas.h2) {
    //             if (asiakas.h1) {
    //               muutosTulevaisuudessa += (asiakas.h1 - asiakas.h2)
    //             } else {
    //               muutosTulevaisuudessa += asiakas.h2
    //             }
    //           }
    //         }
    //       }

    //       paluuarvo.push({
    //         kirjanpitaja: kirjanpitaja,
    //         asiakkaita: asiakkaita,
    //         jatkuvaVeloitus: voimassaolevat,
    //         kate: 0,
    //         kertalaskutuksetViimeinen30Pv: kertalaskutus,
    //         keskimaarainenVeloitus: asiakkaita > 0 ? voimassaolevat / asiakkaita : 0,
    //         tulevatMuutokset: muutosTulevaisuudessa,
    //         yhtiomuodot: [toiminimia, osakeyhtioita, muitaYrityksia],
    //         yhtiomuotoString: toiminimia + '/' + osakeyhtioita + '/' + muitaYrityksia
    //       })

    //     }

    //     return paluuarvo
    //   }),
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(tiedot => {
    //   this.kirjanpitajatDataSource.filter = null
    //   this.kirjanpitajatDataSource.data = tiedot
    // })
    const kirjanpitajienRaakadataObservable = combineLatest([this._valittuKirjanpitajienAikavaliSubject, this._rajauksetSubject, this._valittuAikavaliObservable]).pipe(
      switchMap(([kirjanpitajatAikavali, rajaukset, aikavali]) => {
        const payload: AnnaAsiakkaidenTilastotiedotKirjanpitajittainPyynto = {
          kuukausi: kirjanpitajatAikavali.start,
          rajoitettuKirjanpitajaanAvain: rajaukset.kirjanpitaja ?? null,
          rajoitettuTiimiinAvain: rajaukset.tiimi ?? null,
          kuukausiAlku: aikavali.start,
          kuukausiLoppu: aikavali.end
        }
        return this._firebase.functionsCall<AnnaAsiakkaidenTilastotiedotKirjanpitajittainPyynto, AnnaAsiakkaidenTilastotiedotKirjanpitajittainVastaus>('tilastotAsiakkaidenTilastotiedotKirjanpitajittain', payload).then(vastaus => {
          return vastaus.tiedot
        })
      }),
      lemonShare()
    )

    combineLatest([this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable, kirjanpitajienRaakadataObservable]).pipe(
      map(([kirjanpitajatMap, raakadata]) => {

        this.kirjanpitajittainTableYhteensa.asiakkaita = 0
        this.kirjanpitajittainTableYhteensa.jatkuvakk1 = 0
        this.kirjanpitajittainTableYhteensa.jatkuvakk2 = 0
        this.kirjanpitajittainTableYhteensa.lisayksia = 0
        this.kirjanpitajittainTableYhteensa.poistuma = 0
        this.kirjanpitajittainTableYhteensa.keskim = 0
        this.kirjanpitajittainTableYhteensa.oy = 0
        this.kirjanpitajittainTableYhteensa.tmi = 0
        this.kirjanpitajittainTableYhteensa.muut = 0
        for (const rivi of raakadata) {
          rivi.asiakkaita = rivi.yhtiomuodotOy + rivi.yhtiomuodotTmi + rivi.yhtiomuodotMuut
          rivi.keskimaarainenVeloitus = rivi.jatkuvakk1 / rivi.asiakkaita
          const kirjanpitaja = kirjanpitajatMap.get(rivi.kirjanpitajaAvain)
          if (kirjanpitaja) {
            rivi.kirjanpitaja = kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi
          } else {
            rivi.kirjanpitaja = 'Tuntematon'
          }
          rivi.jatkuvakk2 = rivi.jatkuvakk1 + rivi.vahennykset + rivi.lisaykset
          rivi.yhtiomuotoString = rivi.yhtiomuodotTmi + '/' + rivi.yhtiomuodotOy + '/' + rivi.yhtiomuodotMuut
          this.kirjanpitajittainTableYhteensa.asiakkaita += rivi.asiakkaita
          this.kirjanpitajittainTableYhteensa.jatkuvakk1 += rivi.jatkuvakk1
          this.kirjanpitajittainTableYhteensa.jatkuvakk2 += rivi.jatkuvakk2
          this.kirjanpitajittainTableYhteensa.lisayksia += rivi.lisaykset
          this.kirjanpitajittainTableYhteensa.poistuma += rivi.vahennykset
          this.kirjanpitajittainTableYhteensa.oy += rivi.yhtiomuodotOy
          this.kirjanpitajittainTableYhteensa.tmi += rivi.yhtiomuodotTmi
          this.kirjanpitajittainTableYhteensa.muut += rivi.yhtiomuodotMuut
        }

        this.kirjanpitajittainTableYhteensa.keskim += this.kirjanpitajittainTableYhteensa.jatkuvakk1 / this.kirjanpitajittainTableYhteensa.asiakkaita

        return raakadata

        // const tiimi = rajaukset.tiimi ? await this._kirjanpitajanTiimiService.annaTiimi(rajaukset.tiimi) : null
        // const tiimiSet = tiimi ? this._kirjanpitajanTiimiService.annaTiiminKirjanpitajatPaivalleSet(tiimi, this._dateService.currentNumberDate()) : null
        // const paluuarvo: KirjanpitajatTablenTiedot[] = []
        // for (const kirjanpitaja of kirjanpitajat) {

        //   if (tiimiSet && !tiimiSet.has(kirjanpitaja.avain)) {
        //     continue
        //   }

        //   let voimassaolevat = 0
        //   let muutosTulevaisuudessa = 0
        //   let asiakkaita = 0
        //   let toiminimia = 0
        //   let osakeyhtioita = 0
        //   let muitaYrityksia = 0
        //   for (const asiakas of asiakkaat) {
        //     if (asiakas.k === kirjanpitaja.avain) {
        //       if (asiakas.h1) {
        //         asiakkaita++
        //         voimassaolevat += asiakas.h1
        //         if (asiakas.y === Yritysmuoto.OSAKEYHTIO) {
        //           osakeyhtioita++
        //         } else if (asiakas.y === Yritysmuoto.TOIMINIMI) {
        //           toiminimia++
        //         } else {
        //           muitaYrityksia++
        //         }
        //       }
        //       if (asiakas.h2) {
        //         if (asiakas.h1) {
        //           muutosTulevaisuudessa += (asiakas.h1 - asiakas.h2)
        //         } else {
        //           muutosTulevaisuudessa += asiakas.h2
        //         }
        //       }
        //     }
        //   }

        //   paluuarvo.push({
        //     kirjanpitaja: kirjanpitaja,
        //     asiakkaita: asiakkaita,
        //     jatkuvaVeloitus: voimassaolevat,
        //     kate: 0,
        //     kertalaskutuksetViimeinen30Pv: 0,
        //     keskimaarainenVeloitus: asiakkaita > 0 ? voimassaolevat / asiakkaita : 0,
        //     tulevatMuutokset: muutosTulevaisuudessa,
        //     yhtiomuodot: [toiminimia, osakeyhtioita, muitaYrityksia],
        //     yhtiomuotoString: toiminimia + '/' + osakeyhtioita + '/' + muitaYrityksia
        //   })

        // }

        // return paluuarvo
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(tiedot => {
      this.kirjanpitajittainTableLataa = false
      this.kirjanpitajatDataSource.filter = null
      this.kirjanpitajatDataSource.data = tiedot
    })

    this._versionTarkistusPalvelu.sovelluksenVersioObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(versio => {
      this._versionTarkistusPalvelu.tarkistaVersio(versio)
    })

    this.naytaSuperObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitaja => {
        return kirjanpitaja && kirjanpitaja.rooli === KirjanpitajanRooli.SUPER
      }),
      distinctUntilChanged()
    )

    this.asiakasInfoObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(asiakasInfo => {

      if (asiakasInfo) {
        const labels: string[] = []
        const data: number[] = []
        const colors: string[] = []
        let index = 0
        for (const jakauma of asiakasInfo.yhtiomuotojakauma) {
          data.push(jakauma.maara)
          labels.push(jakauma.nimi)
          colors.push(this.annaVari(index))
          index++
        }

        if (this.yhtiojakaumaChart) {
          this.yhtiojakaumaChart.destroy()
        }

        this.yhtiojakaumaChart = new Chart(this.yhtiojakaumaCanvas.nativeElement.getContext('2d'), {
          type: 'pie',
          data: {
            labels: labels,
            datasets: [{
              data: data,
              backgroundColor: colors
            }]
          },
          options: {
            plugins: {
              title: {
                display: false
              },
              legend: {
                display: false
              }
            },
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1
          }
        })
      }

    })

    laskutuksenMuutosKuukausittainObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(muutokset => {

      const plussaa: number[] = []
      const miinusta: number[] = []
      const yhteensa: number[] = []
      const labels: string[] = []
      for (const muutos of muutokset) {
        // console.log(muutos, muutos.plussaa + muutos.miinusta)
        plussaa.push(muutos.plussaa)
        miinusta.push(muutos.miinusta)
        yhteensa.push(muutos.plussaa + muutos.miinusta)
        labels.push(muutos.vuosikk.month + '/' + muutos.vuosikk.year)
      }

      if (this.kuukausittainenMuutosChart) {
        this.kuukausittainenMuutosChart.destroy()
      }

      this.kuukausittainenMuutosChart = new Chart(this.kuukausittainenMuutosCanvasRef.nativeElement.getContext('2d'), {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Lisäys',
              data: plussaa,
              borderColor: 'rgba(184, 231, 166, 1)',
              backgroundColor: 'rgba(184, 231, 166, 1)',
              fill: false
            },
            {
              label: 'Poistuma',
              data: miinusta,
              borderColor: 'rgba(242, 167, 180, 1)',
              backgroundColor: 'rgba(242, 167, 180, 1)',
              fill: false
            },
            {
              label: 'Muutos',
              data: yhteensa,
              borderColor: 'rgba(153, 196, 231, 1)',
              backgroundColor: 'rgba(153, 196, 231, 1)',
              fill: false
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
            }
          }
        }
      })

      this.kuukausittainenMuutosCanvasOnLataamatta = false

    })

    this.naytaSuperObservable.pipe(
      switchMap(nayta => {
        if (nayta) {
          return combineLatest([this.kirjanpitajittainJakaumaObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotObservable, this._valittuAikavaliObservable, kirjanpitajienRaakadataObservable])
        }
        return EMPTY
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([jakauma, kirjanpitajat, aikavali, kirjanpitajienRaakadata]) => {

      const puretutAikavalit = this._dateService.puraAikavali(aikavali)

      const datasets: any[] = []
      let index = 0
      for (const kirjanpitaja of kirjanpitajat) {
        const luvut: number[] = []

        for (const purettu of puretutAikavalit) {
          let arvo = 0
          let tarvitseeArvoa = true
          for (const tieto of jakauma) {
            if (
              tieto.kirjanpitajanAvain === kirjanpitaja.avain &&
              tieto.vuosi === purettu.year &&
              tieto.kuukausi === purettu.month
            ) {
              arvo = this._currencyService.roundHalfUp(tieto.yhteensa, 2)
              tarvitseeArvoa = false
              break
            }
          }

          if (tarvitseeArvoa) {
            const kuukaudenLocalPaiva: LocalDate = { year: purettu.year, month: purettu.month, day: 2 }
            const kuukaudenPaiva = this._dateService.localDateToDate(kuukaudenLocalPaiva)
            for (const kpdata of kirjanpitajienRaakadata) {
              if (kpdata.kirjanpitajaAvain === kirjanpitaja.avain) {
                if (kpdata.kkJakauma) {
                  const d = kpdata.kkJakauma[purettu.year + '_' + purettu.month] ?? 0
                  arvo = this._currencyService.roundHalfUp(d, 2)
                }
                tarvitseeArvoa = false
                break
              }
            }
          }

          luvut.push(arvo)
        }

        // console.log('Push', kirjanpitaja.avain, kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi, luvut)

        if (luvut.find(luku => luku !== 0)) {
          const color = this.annaVari(index)
          datasets.push({
            label: kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi,
            data: luvut,
            borderColor: color,
            backgroundColor: color,
            fill: false
          })
          index++
        }
        // else {
        //   console.log('Suodatettiin ' + kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi)
        // }

      }

      const labels: string[] = []
      for (const purettu of puretutAikavalit) {
        labels.push(purettu.month + '/' + purettu.year)
      }

      if (this.kuukausittainKirjanpitajittainChart) {
        this.kuukausittainKirjanpitajittainChart.destroy()
      }

      this.kuukausittainKirjanpitajittainChart = new Chart(this.kuukausittainKirjanpitajittainCanvasRef.nativeElement.getContext('2d'), {
        type: 'line',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          scales: {
            x: {
              stacked: false,
              grid: {
                display: false
              }
            },
            y: {
              stacked: false
            }
          }
        }
      })

      this.kuukausittainKirjanpitajittainCanvasOnLataamatta = false

    })

    const kuukausittainenDataObservable = combineLatest([this._valittuAikavaliObservable, this.kuukausittainenJakaumaObservable, laskutuksenMuutosKuukausittainObservable, this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable]).pipe(
      map(([aikavali, jakauma, muutoksetTulevaisuudessa, kirjanpitaja]) => {

        if (!kirjanpitaja) {
          return []
        }

        const rajoitettuKirjanpitajaan = kirjanpitaja && kirjanpitaja.rooli === KirjanpitajanRooli.SUPER ? null : kirjanpitaja.uid
        const kuukaudet = this.puraJaSuodataAikavali(aikavali, rajoitettuKirjanpitajaan)

        const returnKuukaudet: KuukausittainenLaskutusData[] = []
        for (const kuukausi of kuukaudet) {

          const data: KuukausittainenLaskutusData = {
            kuukausi: kuukausi,
            hinnastosta: {
              laskutus: 0,
              lisays: 0,
              poistuma: 0
            },
            laskuista: {
              hyvityksetJatkuva: 0,
              hyvityksetKerta: 0,
              jatkuva: 0,
              kerta: 0
            }
          }

          let tarvitseeTulevaisuusluvun = true
          for (const tieto of jakauma) {
            if (tieto.vuosi === kuukausi.year && tieto.kuukausi === kuukausi.month) {
              data.laskuista.kerta = this._currencyService.roundHalfUp(tieto.kertalaskutus, 2)
              data.laskuista.jatkuva = this._currencyService.roundHalfUp(tieto.jatkuvalaskutus, 2)
              data.laskuista.hyvityksetJatkuva = this._currencyService.roundHalfUp(tieto.jatkuvahyvitykset, 2)
              data.laskuista.hyvityksetKerta = this._currencyService.roundHalfUp(tieto.kertahyvitykset, 2)
              tarvitseeTulevaisuusluvun = false
              break
            }
          }

          if (tarvitseeTulevaisuusluvun) {
            for (const muutos of muutoksetTulevaisuudessa) {
              if (muutos.vuosikk.year === kuukausi.year && muutos.vuosikk.month === kuukausi.month) {
                // console.log(muutos)
                // data.laskuista.kerta = this._currencyService.roundHalfUp(tieto.kertalaskutus, 2)
                data.laskuista.jatkuva = this._currencyService.roundHalfUp(muutos.yhteensa, 2)
                // data.laskuista.hyvityksetJatkuva = this._currencyService.roundHalfUp(tieto.jatkuvahyvitykset, 2)
                // data.laskuista.hyvityksetKerta = this._currencyService.roundHalfUp(tieto.kertahyvitykset, 2)
                tarvitseeTulevaisuusluvun = false
                break
              }
            }
          }

          for (const muutos of muutoksetTulevaisuudessa) {
            if (muutos.vuosikk.year === kuukausi.year && muutos.vuosikk.month === kuukausi.month) {

              data.hinnastosta.lisays = muutos.plussaa
              data.hinnastosta.poistuma = muutos.miinusta
              data.hinnastosta.laskutus = muutos.yhteensa

              break
            }
          }

          returnKuukaudet.push(data)

        }

        return returnKuukaudet

      }),
      lemonShare()
    )

    this.naytaSpeccerObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitaja => this._kirjanpitajanTiimiService.isSpeccer(kirjanpitaja?.uid))
    )

    this.kuukausittaisenDatanRivitObservable = combineLatest([kuukausittainenDataObservable, this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable]).pipe(
      map(([kuukausidata, kirjanpitaja]) => {

        const otsikkorivi: KuukausittaisenTablenRivi = {
          bold: true,
          cols: ['']
        }

        const labelsJatkuva: string[] = []
        if (this._kirjanpitajanTiimiService.isSpeccer(kirjanpitaja?.uid)) {
          labelsJatkuva.push('Jatkuva HINNASTOSTA')
        } else {
          labelsJatkuva.push('Jatkuva laskutus')
        }

        const myyntiYhteensaRivi: KuukausittaisenTablenRivi = { bold: true, cols: ['Myynti yhteensä'] }
        const jatkuvaLaskutusRivi: KuukausittaisenTablenRivi = { bold: false, cols: labelsJatkuva }
        const kertalaskutusRivi: KuukausittaisenTablenRivi = { bold: false, cols: ['Kertalaskutus'] }
        const kertahyvaritRivi: KuukausittaisenTablenRivi = { bold: false, cols: ['Hyvitykset, kerta'] }
        const jatkuvahyvaritRivi: KuukausittaisenTablenRivi = { bold: false, cols: ['Hyvitykset, jatkuva'] }

        const muutosRivi: KuukausittaisenTablenRivi = { bold: true, cols: ['Muutos'] }
        const lisaysRivi: KuukausittaisenTablenRivi = { bold: false, cols: ['Lisäys'] }
        const poistumaRivi: KuukausittaisenTablenRivi = { bold: false, cols: ['Poistuma'] }

        const debugRivi: KuukausittaisenTablenRivi = { bold: true, cols: ['Debug'] }
        const jatkuvaHinnoistaRivi: KuukausittaisenTablenRivi = { bold: false, cols: ['Jatkuva LASKUISTA'] }
        const erotusRivi: KuukausittaisenTablenRivi = { bold: false, cols: ['Erotus'] }

        for (const data of kuukausidata) {
          otsikkorivi.cols.push(data.kuukausi.month + '/' + data.kuukausi.year)

          const summa = data.laskuista.kerta + data.hinnastosta.laskutus + data.laskuista.hyvityksetJatkuva + data.laskuista.hyvityksetKerta
          myyntiYhteensaRivi.cols.push(this._currencyService.formatoiRaha(summa, 'EUR', 'fi'))

          jatkuvaLaskutusRivi.cols.push(this._currencyService.formatoiRaha(data.hinnastosta.laskutus, 'EUR', 'fi'))
          kertalaskutusRivi.cols.push(this._currencyService.formatoiRaha(data.laskuista.kerta, 'EUR', 'fi'))
          kertahyvaritRivi.cols.push(this._currencyService.formatoiRaha(data.laskuista.hyvityksetKerta, 'EUR', 'fi'))
          jatkuvahyvaritRivi.cols.push(this._currencyService.formatoiRaha(data.laskuista.hyvityksetJatkuva, 'EUR', 'fi'))

          muutosRivi.cols.push(this._currencyService.formatoiRaha(data.hinnastosta.lisays + data.hinnastosta.poistuma, 'EUR', 'fi'))
          lisaysRivi.cols.push(this._currencyService.formatoiRaha(data.hinnastosta.lisays, 'EUR', 'fi'))
          poistumaRivi.cols.push(this._currencyService.formatoiRaha(data.hinnastosta.poistuma, 'EUR', 'fi'))

          debugRivi.cols.push('')
          jatkuvaHinnoistaRivi.cols.push(this._currencyService.formatoiRaha(data.laskuista.jatkuva, 'EUR', 'fi'))
          erotusRivi.cols.push(this._currencyService.formatoiRaha(data.hinnastosta.laskutus - data.laskuista.jatkuva, 'EUR', 'fi'))
        }

        const rivit: KuukausittaisenTablenRivi[] = []

        rivit.push(otsikkorivi)

        rivit.push(myyntiYhteensaRivi)
        rivit.push(jatkuvaLaskutusRivi)
        rivit.push(kertalaskutusRivi)
        rivit.push(kertahyvaritRivi)
        rivit.push(jatkuvahyvaritRivi)

        rivit.push(muutosRivi)
        rivit.push(lisaysRivi)
        rivit.push(poistumaRivi)

        if (this._kirjanpitajanTiimiService.isSpeccer(kirjanpitaja?.uid)) {
          rivit.push(debugRivi)
          rivit.push(jatkuvaHinnoistaRivi)
          rivit.push(erotusRivi)
        }

        return rivit

      })
    )

    kuukausittainenDataObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kuukaudenTiedot => {

      if (this.kuukausittainChart) {
        this.kuukausittainChart.destroy()
      }

      if (!kuukaudenTiedot?.length) {
        return
      }

      const kerta: number[] = []
      const jatkuva: number[] = []
      const kertahyvitykset: number[] = []
      const jatkuvahyvitykset: number[] = []
      const labels: string[][] = []
      // let summa = 0
      for (const data of kuukaudenTiedot) {
        kerta.push(data.laskuista.kerta)
        jatkuva.push(data.hinnastosta.laskutus)
        kertahyvitykset.push(data.laskuista.hyvityksetKerta)
        jatkuvahyvitykset.push(data.laskuista.hyvityksetJatkuva)
        // summa =
        const labelsForMonth = [
          data.kuukausi.month + '/' + data.kuukausi.year
        ]
        labels.push(labelsForMonth)
      }

      this.kuukausittainChart = new Chart(this.kuukausittainCanvasRef.nativeElement.getContext('2d'), {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Jatkuvalaskutus',
              data: jatkuva,
              backgroundColor: 'rgba(153, 196, 231, 1)',
              borderColor: 'rgba(153, 196, 231, 1)'
            },
            {
              label: 'Kertalaskutus',
              data: kerta,
              backgroundColor: 'rgba(184, 231, 166, 1)',
              borderColor: 'rgba(184, 231, 166, 1)'
            },
            {
              label: 'Hyvitykset, kerta',
              data: kertahyvitykset,
              backgroundColor: 'rgba(242, 167, 180, 1)',
              borderColor: 'rgba(242, 167, 180, 1)'
            },
            {
              label: 'Hyvitykset, jatkuva',
              data: jatkuvahyvitykset,
              backgroundColor: 'rgba(247, 70, 74, 1)',
              borderColor: 'rgba(247, 70, 74, 1)'
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              stacked: true
            }
          }
        }
      })

      this.kuukausittainCanvasDataOnLataamatta = false

    })

  }






  private annaVari(index: number): string {
    return index < this.varit.length ? this.varit[index] : this.arvoVari()
  }

  private arvoVari(): string {
    return 'rgba(' + this.getRandomInt(0, 255) + ',' + this.getRandomInt(0, 255) + ',' + this.getRandomInt(0, 255) + ',1)'
  }

  private getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }


  private puraJaSuodataAikavali(aikavali: KuukausiAikavali, rajoitettuKirjanpitajaanAvain: string): LocalMonth[] {
    const purettu = this._dateService.puraAikavali(aikavali)
    const takarajaKk = rajoitettuKirjanpitajaanAvain ? 11 : 10
    return purettu.filter(a => {
      return a.year > 2018 || (a.year === 2018 && a.month >= takarajaKk)
    })
  }

}
