<h1 class="raportti-header" style="margin-top: 20px;">
  Pääkirja
</h1>
<ng-container *ngIf="paakirjanDataObservable | async; let data">
  <ng-container *ngIf="!(loadingSubject | async) else loading">
    <div style="display: flex; justify-content: space-between; align-items: flex-end;">
      <div style="font-size: 16px; font-weight: bold; line-height: 24px;">{{(hakuvaihtoehdotObservable | async)?.alkaa| lnumberpvml:'fi'}} - {{(hakuvaihtoehdotObservable | async)?.loppuu | lnumberpvml:'fi'}}</div>
      <div *ngIf="lastSucessfullyUpdated" class="gray-text" style="font-size: 11px; line-height: 13px;">Päivitetty {{ lastSucessfullyUpdated | ltimestamptime | async }}</div>
    </div>
    <table class="report-table" (click)="handleClick($event, data)">
      <!-- Note: this is needed to be able to use table-layout: fixed with nth-child(x) selectors for widths. -->
      <thead>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody *ngFor="let row of data.r; trackBy: trackAccountRowByAccountNumberFn">
        <tr class="rran">
          <td colspan="5">
            {{row.n}}
            <span class="r-button" *ngIf="row.showR" (click)="avaaReskontraValilehti(row.n)">R</span>
            <span class="kirjanpito-raportit-poistotilin-tunniste" *ngIf="row.onkoPoistotili" matTooltip="Automaattinen poistotili">A</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="rrh">
          <td>Laji</td>
          <td>Tosite</td>
          <td>Pvm</td>
          <td>Selite</td>
          <td>ALV</td>
          <td>Debet</td>
          <td>Kredit</td>
          <td>Saldo</td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td>Alkusaldo</td>
          <td></td>
          <td></td>
          <td></td>
          <td [attr.data-a]="row.a" class="ah" [class.blue-link-text]="row.o">{{row.o | number:'1.2-2'}}</td>
        </tr>
        <tr style="display: none;">
          <td colspan="8">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </td>
        </tr>
        <tr *ngFor="let d of row.d; let i = index" [attr.data-i]="i" class="r" [class.laajennettu]="d.l">
          <td [class.projekti-dot]="d.b" [class]="d.b ? 'a' + d.b : null">{{d.ma}}</td>
          <td [attr.data-n]="d.n">{{d.n}}</td>
          <td>{{d.p | lnumberpvmlnarrow:'fi'}}</td>
          <td>
            <span *ngIf="d.a" [class.blue-text]="!d.rt" [class.dark-purple-text]="d.rt">{{d.a}}</span>
            <span *ngIf="d.c" class="cursive-text">{{d.c}}</span>
            {{d.r}} {{d.e}}
          </td>
          <td>{{d.v}}</td>
          <td>{{d.d | number:'1.2-2'}}</td>
          <td>{{d.k | number:'1.2-2'}}</td>
          <td>{{d.s | number:'1.2-2'}}</td>
        </tr>
        <tr class="rrdy">
          <td></td>
          <td></td>
          <td></td>
          <td>Yhteensä</td>
          <td></td>
          <td>{{row.b | number:'1.2-2'}}</td>
          <td>{{row.k | number:'1.2-2'}}</td>
          <td>{{row.s | number:'1.2-2'}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>