import { OnInit, Component, OnDestroy, ErrorHandler, ChangeDetectorRef, Output, EventEmitter, HostListener, NgZone, ElementRef } from '@angular/core'
import { FirebaseLemonator } from '../../_angular/service/firebase-lemonator.service'

import { FormGroup, FormControl, AbstractControl } from '@angular/forms'

import { Kirjanpitotili, Kirjausrivi, RaporttiPdfResponse, RaporttiRequest } from '../../_jaettu-lemonator/model/kirjanpito'
import { ReskontraPaivitaKirjauksetTyojono, Reskontravienti, ReskontraLemonaidiinQueueTyojonoData } from '../../_jaettu-lemonator/model/reskontra'

import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'

import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom, of as observableOf } from 'rxjs'
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators'
import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { environment } from 'environments/environment'
import { EnvironmentType } from 'app/app.environment'
import { AsiakkaanMaksutapa } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjanpitoReskontraUriService } from 'app/_jaettu-lemonator/service/kirjanpito-reskontra-uri.service'
import { NumberDate } from 'app/_shared-core/model/common'
import { animate, style, transition, trigger } from '@angular/animations'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { RaporttiType } from 'app/_jaettu/model/reports-shared'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { KirjanpitoNavigationService } from 'app/_angular/service/kirjanpito/kirjanpito-navigation.service'
import { IFirestoreBatch } from 'app/_jaettu-angular/base-firebase.service'


export interface ReskontraOpenMessage {
  kirjausAvain: string
  kirjausriviAvain: string
  pvm: NumberDate
  tili: string
  reskontraTila: Kirjausrivi['r']
}

interface MainForm {
  valittuTili: FormControl<string>
  pvm: FormControl<Date>
  vainAvoimet: FormControl<boolean>
}


interface RenderableReskontraGroup {
  order: number
  loading: boolean
  leftColumnViennit: Reskontravienti[]
  rightColumnViennit: Reskontravienti[]
  difference: number
  displayGroupBorder: boolean
  hasVientiOnBothSides: boolean
}

interface ReskontravientiAndOrder extends Reskontravienti {
  order: number
}
@Component({
  selector: '[app-kirjanpito-reskontra]',
  templateUrl: './reskontra.component.html',
  styleUrls: ['./reskontra.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class KirjanpitoReskontraComponent implements OnInit, OnDestroy {

  @Output() openKirjaus: EventEmitter<{ kirjausAvain: string, pvm: NumberDate, maksutapa: number }> = new EventEmitter()

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const asHtmlElement = event.target as HTMLElement
    if (
      asHtmlElement?.classList.contains('mat-datepicker-input') ||
      asHtmlElement?.classList.contains('mat-icon') ||
      asHtmlElement?.classList.contains('mat-button') ||
      asHtmlElement?.classList.contains('mat-select-min-line') ||
      asHtmlElement?.classList.contains('mat-select-arrow') ||
      asHtmlElement?.classList.contains('blue-arrow') ||
      asHtmlElement?.classList.contains('gray-chain')
    ) {
      return
    }

    this.selectedVientiSubject.next(null)
  }

  private _ngUnsubscribe = new Subject<void>()

  namename: string = 'afasdfasdfayuiopålkjhgf' + new Date().getTime()
  private _saveStarted: boolean
  showCommonError: boolean = false

  reskontraTilitObservable: Observable<Kirjanpitotili[]>
  maksutavatMap: Map<number, AsiakkaanMaksutapa> = new Map()

  renderableReskontraGroups: RenderableReskontraGroup[]

  selectedVientiSubject: BehaviorSubject<ReskontravientiAndOrder> = new BehaviorSubject(null)
  // selectedDebitVientiAvainSubject: BehaviorSubject<string> = new BehaviorSubject(null)
  vientiKeyToOrderMap: Map<string, number> = new Map()
  // private _cachedReskontraChanges: { changedVientiAvin: string, newConnections: Reskontravienti['a'] }[] = []
  // private _cacheChangedSubject: Subject<boolean> = new Subject()

  totalDebit: number
  totalKredit: number

  debugUrlStart: string

  loading: boolean

  form: FormGroup<MainForm>

  private _kirjausriviClickedChannel: BroadcastChannel

  constructor(
    private _firebase: FirebaseLemonator,
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _tilikarttaService: TilikarttaService,
    private _kayttajaService: KirjautunutKayttajaService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dateService: DateService,
    private _reskontraUriService: KirjanpitoReskontraUriService,
    private _ngZone: NgZone,
    private _timestampService: TimestampService,
    private _currencyService: CurrencyService,
    private _snackbar: MatSnackBar,
    private _fileSaverService: FileSaverService,
    private _ladataanService: LadataanService,
    private _elRef: ElementRef<HTMLElement>,
    private _kirjanpitoNavigationService: KirjanpitoNavigationService
  ) { }

  ngOnInit() {

    this.form = new FormGroup<MainForm>({
      'valittuTili': new FormControl<string>(null),
      'pvm': new FormControl<Date>(null),
      'vainAvoimet': new FormControl<boolean>(null)
    })

    combineLatest([
      this._kirjanpitoNavigationService.kirjanpidonTiliObservable,
      this._kirjanpitoNavigationService.raporttiValinPaattymispaivaObservable
    ])
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(([tili, paattymispaiva]) => {
        this.form.get('valittuTili')?.setValue(tili)
        this.form.get('pvm')?.setValue(this._dateService.numberToDate(paattymispaiva))
      })


    this.reskontraTilitObservable = this._tilikarttaService.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable.pipe(
      map(tilit => {
        return tilit.filter(t => t.reskontraActive)
      })
    )

    const rawReskontraViennitObservable: Observable<Reskontravienti[]> = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.form.get('valittuTili').valueChanges,
      this.form.get('pvm').valueChanges
    ]).pipe(
      tap(() => {
        this.loading = true
      }),
      switchMap(([asiakastiedot, reskontraTilinNimi, pvm]) => {
        if (!asiakastiedot?.avain) {
          return observableOf<Reskontravienti[]>([])
        }

        const reskontraTili = reskontraTilinNimi?.split(' ')?.[0]

        if (!reskontraTili) {
          return observableOf<Reskontravienti[]>([])
        }

        const pvmNum = this._dateService.dateToNumber(pvm)
        return this._firebase.firestoreCollection<Reskontravienti>(this._reskontraUriService.getReskontraViennitCollection(asiakastiedot.avain))
          .where('t', '==', reskontraTili)
          .where('po', '==', false)
          .where('p', '<=', pvmNum)
          .listen()
        // .pipe(
        //   map(viennit => {
        //     if (vainAvoimet) {
        //       return viennit.filter(v => !v.c || v.c > pvmNum)
        //     }
        //     return viennit
        //   }),
        // )
      })
    )

    combineLatest([
      rawReskontraViennitObservable,
      this.form.get('vainAvoimet').valueChanges
      // this._cacheChangedSubject.asObservable().pipe(startWith(null))
    ]).pipe(takeUntil(this._ngUnsubscribe)
    ).subscribe(([viennit, vainAvoimet]) => {

      this.totalDebit = 0
      this.totalKredit = 0

      // for (const change of (this._cachedReskontraChanges ?? [])) {
      //   /**
      //     * Remove from old location
      //     * Loops through ALL viennit, because the cached version might have changed multiple times and may not contain the original oldDebit key anymore.
      //     */
      //   for (const vienti of viennitPlusCached) {

      //     if (!vienti.a) { vienti.a = [] }

      //     if (vienti.av === change.changedVientiAvin) {
      //       vienti.a = change.newConnections
      //     }

      //     if (vienti.a?.includes(change.changedVientiAvin)) {
      //       this._removeLinkToVienti(vienti, change.changedVientiAvin)
      //     }

      //     if (change.newConnections.includes(vienti.av)) {
      //       vienti.a.push(change.changedVientiAvin)
      //     }
      //   }
      // }


      let newRenderableRows = this._convertToRenderableReskontraGroup([...viennit])

      if (vainAvoimet) {
        newRenderableRows = newRenderableRows.filter(row => row.difference !== 0)
      }

      this.renderableReskontraGroups = newRenderableRows

      for (const group of this.renderableReskontraGroups) {
        for (const leftCol of group.leftColumnViennit) {
          this.totalDebit += leftCol.s
          this.vientiKeyToOrderMap.set(leftCol.av, group.order)
        }
        for (const rightCol of group.rightColumnViennit) {
          this.totalKredit += rightCol.s
          this.vientiKeyToOrderMap.set(rightCol.av, group.order)

        }
      }

      this.loading = false
      this._changeDetectorRef.markForCheck()

    })

    combineLatest([
      this._kayttajaService.kirjanpitajaOnDevaajaObservable,
      this._asiakasService.nykyinenAsiakasAvainObservable,
    ]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([isDev, asiakastiedot]) => {

      if (asiakastiedot?.avain && isDev) {
        const project = environment.environment === EnvironmentType.PRODUCTION || environment.environment === EnvironmentType.BETA ? 'eu-lemonator' : 'dev-lemonator-web'
        this.debugUrlStart = 'https://console.firebase.google.com/project/' + project + '/firestore/data/~2F' + this._reskontraUriService.getReskontraViennitCollection(asiakastiedot.avain)
      }
    })

    this._asiakasService.nykyisenAsiakkaanKaikkiMaksutavatObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(maksutavat => {
      for (const mt of maksutavat ?? []) {
        this.maksutavatMap.set(mt.tunniste, mt)
      }
    })


    this.reskontraTilitObservable.pipe(
      take(1)
    ).subscribe(tilit => {
      if (tilit?.length) {
        // Starting value
        this.valittuTili.setValue(tilit[0].numero + ' ' + tilit[0].nimi)
      }
    })

    this._kirjausriviClickedChannel = new BroadcastChannel('reskontra-kirjausrivi-clicked')
    this._kirjausriviClickedChannel.addEventListener('message', async (event: MessageEvent<ReskontraOpenMessage>) => {
      const data = event.data
      if (!data) { return }

      if (data.pvm > this._dateService.dateToNumber(this.pvm.value)) { // The date of the messaged reskontra vienti is later than the currently select date.
        this.pvm.setValue(this._dateService.numberToDate(data.pvm))
      }
      if (data.reskontraTila === 'ok' && this.vainAvoimet.value) { // Only non-closed viennit are shown atm but the messaged vienti is closed
        this.vainAvoimet.setValue(false)
      }
      const reskontratilit = await firstValueFrom(this.reskontraTilitObservable)
      const messagedTili = reskontratilit.find(t => t.numero === data.tili)
      const tilinumeroJaNimi = messagedTili.numero + ' ' + messagedTili.nimi
      if (tilinumeroJaNimi !== this.valittuTili.value) {
        this.valittuTili.setValue(tilinumeroJaNimi)
      }

      const vientiAvain = this._reskontraUriService.createVientiAvain(data.kirjausAvain, data.kirjausriviAvain)
      return this._waitForAndScrollToVienti(vientiAvain).then(() => {
        this._selectVientiByAvain(vientiAvain)
        this._changeDetectorRef.markForCheck()
      })
    })


    // Starting values
    this.vainAvoimet.setValue(true)
    this.pvm.setValue(new Date())
  }


  get valittuTili(): AbstractControl<string> {
    return this.form.get('valittuTili')
  }

  get pvm(): AbstractControl<Date> {
    return this.form.get('pvm')
  }

  get vainAvoimet(): AbstractControl<boolean> {
    return this.form.get('vainAvoimet')
  }


  async save() {

    // if (!this.projektiForm.valid) {
    //   this._validationService.merkitseKokoLomakeKosketuksi(this.projektiForm)
    //   return
    // }

    // if (this._saveStarted) {
    //   return
    // }
    // this._saveStarted = true
    // this._ladataanService.aloitaLataaminen()

    // const batch = this._firebase.firestoreBatch()
    // const asiakastiedot = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    // const projektitArray = this.projektiForm.get('projektitArray') as UntypedFormArray
    // const kaikkiProjektit = projektitArray.controls ? projektitArray.controls as UntypedFormGroup[] : []

    // for (const form of kaikkiProjektit) {
    //   await this.addProjektiToBatch(asiakastiedot.avain, form, batch)
    // }

    // const toBePersisted = this._addedProjektitSubject.value
    // this._addedProjektitSubject.next([])
    // return batch.commit().then(() => {
    //   this._addedProjektitSubject.value.length = 0
    //   this._addedProjektitSubject.next(this._addedProjektitSubject.value)
    // }).catch(error => {
    //   this._addedProjektitSubject.next(toBePersisted)
    //   this.showCommonError = true
    //   this._errorHandler.handleError(new Error('Projektit save failed ' + error?.toString()))
    // }).finally(() => {
    //   this._saveStarted = false
    //   this._ladataanService.lopetaLataaminen()
    // })
  }

  vientiClicked(vienti: Reskontravienti, order: number, event: Event) {
    event.stopPropagation()
    event.preventDefault()

    if (vienti.av !== this.selectedVientiSubject.value?.av) {
      const vientiAndOrder = Object.assign(vienti, { order: order })
      this.selectedVientiSubject.next(vientiAndOrder)
    } else {
      this.selectedVientiSubject.next(null)
    }

  }

  autoselectVienti(group: RenderableReskontraGroup, event: Event) {
    event.stopPropagation()
    event.preventDefault()

    const vienti = group.leftColumnViennit?.[0] ?? group.rightColumnViennit[0]

    const vientiAndOrder = Object.assign(vienti, { order: group.order })
    this.selectedVientiSubject.next(vientiAndOrder)
  }

  attach(newRow: RenderableReskontraGroup, event: Event) {
    event.stopPropagation()
    if (!this.selectedVientiSubject.value) {
      return
    }
    const selectedVienti = this.selectedVientiSubject.value

    const oldRow: RenderableReskontraGroup = this.renderableReskontraGroups.find(rr => rr.order === selectedVienti.order)

    this._detachAttach({ ...this.selectedVientiSubject.value }, oldRow, newRow)
    this.selectedVientiSubject.next(null)
  }

  detach(row: RenderableReskontraGroup, event: Event) {
    event.stopPropagation()
    this._detachAttach({ ...this.selectedVientiSubject.value }, row, null)
    this.selectedVientiSubject.next(null)
  }

  private _detachAttach(changed: Reskontravienti, oldGroup: RenderableReskontraGroup, newGroup: RenderableReskontraGroup) {
    const anyLoading = oldGroup.loading || newGroup?.loading

    if (anyLoading) {
      return
    }

    try {
      Promise.all([firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable), this._kayttajaService.getKirjanpitajanTiedot()]).then(async ([asiakastiedot, kayttajatiedot]) => {
        const batch = this._firebase.firestoreBatch()

        const oldGroupViennit = [...oldGroup.leftColumnViennit, ...oldGroup.rightColumnViennit]
        const oldGroupClosedDate = this._getClosedDateIfClosed(oldGroupViennit.filter(v => v.av !== changed.av))
        this._addAllGroupDetachesToBatch(asiakastiedot.avain, changed, oldGroup, oldGroupClosedDate, batch)

        let newRowClosedDate = null
        if (newGroup) {
          newRowClosedDate = this._getClosedDateIfClosed([changed, ...newGroup.leftColumnViennit, ...newGroup.rightColumnViennit])
          this._addAllGroupAttachesToBatch(asiakastiedot.avain, changed, newGroup, newRowClosedDate, batch)
        }

        const updatedViennitSet: Set<string> = new Set()
        updatedViennitSet.add(changed.av)
        const updatedViennit = Array.from(updatedViennitSet)

        oldGroupViennit.forEach(v => updatedViennitSet.add(v.av))

        const changedVientiUri = this._reskontraUriService.getReskontranVientiUri(asiakastiedot.avain, changed.av)
        const changeVientiHistoriaUri = this._reskontraUriService.getReskontranVientHistoriaUri(asiakastiedot.avain, changed.av, this._firebase.firestoreCreateId())
        const newLeftColumnViennit = newGroup?.leftColumnViennit ?? []
        const newRightColumnViennit = newGroup?.rightColumnViennit ?? []
        const newConnections = newLeftColumnViennit.concat(newRightColumnViennit).map(c => c.av)
        newConnections.forEach(c => updatedViennitSet.add(c))

        changed.a = newConnections
        changed.c = newRowClosedDate
        changed.u = this._timestampService.now()
        changed.y = kayttajatiedot.uid

        const paivitaKirjauksetTyojono: ReskontraPaivitaKirjauksetTyojono = {
          asiakasAvain: asiakastiedot.avain,
          updatedViennit: Array.from(updatedViennitSet)
        }

        const paivitaKirjausTyojonoDoc = this._reskontraUriService.getReskontraPaivitaKirjauksetTyojonoUri(asiakastiedot.avain, this._firebase.firestoreCreateId())

        const reskontraLemonaidiinCachedTyojonoData: ReskontraLemonaidiinQueueTyojonoData = {
          asiakasAvain: asiakastiedot.avain,
          updatedViennit: this._firebase.firestoreArrayUnion(...updatedViennit) as unknown as string[],
          latestUpdate: this._timestampService.now()
        }
        const reskontraCachedTyojonoDataUri = this._reskontraUriService.getReskontraLemonaidiinQueuedTyojonoDataUri(asiakastiedot.avain)

        batch.set(changedVientiUri, changed, { merge: true })
        batch.set(changeVientiHistoriaUri, changed)
        batch.set(paivitaKirjausTyojonoDoc, paivitaKirjauksetTyojono)
        batch.set(reskontraCachedTyojonoDataUri, reskontraLemonaidiinCachedTyojonoData, { merge: true })

        if (newRowClosedDate) {
          this._renderableReskontraGroupPhantomChange(changed, oldGroup, newGroup)
          await this._sleep(2000)
        }
        return batch.commit().then(async () => {
          oldGroup.loading = false
          if (newGroup) {
            newGroup.loading = false
          }
        })
      })

    } catch (err) {
      this._errorHandler.handleError('Reskontravienti ' + changed.av + ' attaching failed! ' + (err?.message ?? 'unknown error'))
    }
  }

  private _renderableReskontraGroupPhantomChange(changed: Reskontravienti, oldGroup: RenderableReskontraGroup, newGroup: RenderableReskontraGroup) {
    for (const [idx, left] of oldGroup.leftColumnViennit.entries()) {
      if (left.av === changed.av) {
        oldGroup.leftColumnViennit.splice(idx, 1)
        break
      }
    }
    for (const [idx, right] of oldGroup.rightColumnViennit.entries()) {
      if (right.av === changed.av) {
        oldGroup.rightColumnViennit.splice(idx, 1)
        break
      }
    }
    oldGroup.difference = this._calcRowTotal([...oldGroup.leftColumnViennit, ...oldGroup.rightColumnViennit])
    newGroup.hasVientiOnBothSides = oldGroup.leftColumnViennit.length > 0 && oldGroup.rightColumnViennit.length > 0

    if (!newGroup) {
      return
    }

    if (changed.d === 'd') {
      newGroup.leftColumnViennit.push(changed)
    } else {
      newGroup.rightColumnViennit.push(changed)
    }
    newGroup.difference = this._calcRowTotal([...newGroup.leftColumnViennit, ...newGroup.rightColumnViennit])
    newGroup.hasVientiOnBothSides = newGroup.leftColumnViennit.length > 0 && newGroup.rightColumnViennit.length > 0

  }

  private _addAllGroupAttachesToBatch(asiakasAvain: string, toAttach: Reskontravienti, group: RenderableReskontraGroup, closedDate: Reskontravienti['c'], batch: IFirestoreBatch) {
    const uriStart = this._reskontraUriService.getReskontraViennitCollection(asiakasAvain)

    for (const left of group.leftColumnViennit) {
      this._addSingleAttachToBatch(uriStart + '/' + left.av, toAttach, left, closedDate, batch)
    }
    for (const right of group.rightColumnViennit) {
      this._addSingleAttachToBatch(uriStart + '/' + right.av, toAttach, right, closedDate, batch)
    }
  }

  private _addSingleAttachToBatch(uri: string, toAttach: Reskontravienti, toAttachTo: Reskontravienti, closedDate: Reskontravienti['c'], batch: IFirestoreBatch) {
    if (toAttach.av === toAttachTo.av) {
      console.log('Can\'t attach ' + toAttach.av + ' to itself.')
      return
    }
    if (!toAttachTo.a.includes(toAttach.av)) {
      toAttachTo.a.push(toAttach.av)
    }
    const updateData: Pick<Reskontravienti, 'a' | 'c'> = {
      a: toAttachTo.a,
      c: closedDate ?? null
    }
    batch.update(uri, updateData)
  }


  private _addAllGroupDetachesToBatch(asiakasAvain: string, toDetach: Reskontravienti, group: RenderableReskontraGroup, closedDate: Reskontravienti['c'], batch: IFirestoreBatch) {
    const uriStart = this._reskontraUriService.getReskontraViennitCollection(asiakasAvain)

    for (const left of group.leftColumnViennit) {
      this._addSingleDetachToBatch(uriStart + '/' + left.av, toDetach, left, closedDate, batch)
    }
    for (const right of group.rightColumnViennit) {
      this._addSingleDetachToBatch(uriStart + '/' + right.av, toDetach, right, closedDate, batch)
    }
  }

  private _addSingleDetachToBatch(uri: string, toDetach: Reskontravienti, toDetachFrom: Reskontravienti, closedDate: Reskontravienti['c'], batch: IFirestoreBatch) {
    toDetachFrom.a.splice(toDetachFrom.a.findIndex(attached => attached === toDetach.av), 1)
    const updateData: Pick<Reskontravienti, 'a' | 'c'> = {
      a: toDetachFrom.a,
      c: closedDate ?? null
    }
    batch.update(uri, updateData)
  }

  private _convertToRenderableReskontraGroup(viennit: Reskontravienti[]) {
    const output: RenderableReskontraGroup[] = []

    const alreadyConvertedSet: Set<string> = new Set()

    for (const v of viennit) {
      if (alreadyConvertedSet.has(v.av)) {
        continue
      }
      const renderableGroup: RenderableReskontraGroup = { order: null, loading: false, leftColumnViennit: [], rightColumnViennit: [], difference: 0, hasVientiOnBothSides: false, displayGroupBorder: false }
      const connectedViennit = viennit.filter(vv => v.a?.includes(vv.av))

      let leftColumnTotal = 0
      let rightColumnTotal = 0
      for (const toConvert of connectedViennit.concat([v])) {
        if (toConvert.d === 'd') {
          leftColumnTotal += toConvert.s
          renderableGroup.leftColumnViennit.push(toConvert)
        } else if (toConvert.d === 'k') {
          rightColumnTotal += toConvert.s
          renderableGroup.rightColumnViennit.push(toConvert)
        }
        alreadyConvertedSet.add(toConvert.av)
      }

      // Otetaan pois ryppäiden ääriviivat silloin kun ryppäs mahtuu yhdelle riville. Poikkeuksena kahden viennin rypäs, joka erottaa, jolloin näkyy "puuttuu xxx,xx" -teksti. Tällöin ääriviivat myös mukaan.
      renderableGroup.difference = this._currencyService.roundHalfUp(leftColumnTotal, 2) - this._currencyService.roundHalfUp(rightColumnTotal, 2)
      renderableGroup.hasVientiOnBothSides = renderableGroup.leftColumnViennit.length > 0 && renderableGroup.rightColumnViennit.length > 0

      if (renderableGroup.hasVientiOnBothSides &&
        (renderableGroup.difference || (renderableGroup.leftColumnViennit.length > 1 || renderableGroup.rightColumnViennit.length > 1))
      ) {

        renderableGroup.displayGroupBorder = true
      }

      output.push(renderableGroup)
    }

    // output.sort((a, b) => {
    //   const sortByA = a.leftColumnViennit?.[0] ?? a.rightColumnViennit?.[0]
    //   const sortByB = b.leftColumnViennit?.[0] ?? b.rightColumnViennit?.[0]

    //   if (sortByA.p !== sortByB.p) {
    //     return sortByA.p - sortByB.p
    //   } else {
    //     return sortByA.s - sortByB.s
    //   }
    // })

    for (const [idx, o] of output.entries()) {
      o.order = idx
    }
    return output
  }

  // @HostListener('document:keydown.arrowup', ['$event'])
  // moveVientiUp(event: KeyboardEvent) {
  //   // const element = event.target as HTMLElement
  //   if (
  //     !this.selectedVientiSubject.value // Nothing selected, ignore
  //   ) {
  //     return
  //   }
  //   event.stopPropagation()
  //   event.preventDefault()
  //   this._moveVientiUpOrDown('up', this.selectedVientiSubject.value)
  // }

  // @HostListener('document:keydown.arrowdown', ['$event'])
  // moveVientiDown(event: KeyboardEvent) {
  //   // const element = event.target as HTMLElement
  //   if (
  //     !this.selectedVientiSubject.value // Nothing selected, ignore
  //   ) {
  //     return
  //   }
  //   event.stopPropagation()
  //   event.preventDefault()
  //   this._moveVientiUpOrDown('down', this.selectedVientiSubject.value)
  // }

  // private _moveVientiUpOrDown(direction: 'up' | 'down', selectedVienti: Reskontravienti) {
  //   if (Array.from(this.vientiKeyToOrderMap.keys()).length <= 1) {
  //     // There's only one item in the list, don't do anything
  //     return
  //   }

  //   const currentOrder = this.vientiKeyToOrderMap.get(selectedVienti.av)
  //   const orderAfterChange = direction === 'up' ? (currentOrder - 1) : (currentOrder + 1)
  //   const currentRow = this.renderableReskontraRows.find(r => r.order === currentOrder)

  //   if (orderAfterChange < 0) {
  //     // Reached empty box, remove all connections
  //     return this._detachAttach(selectedVienti, currentRow, null)
  //   }

  //   const rowAfterChange = this.renderableReskontraRows.find(r => r.order === orderAfterChange)

  //   if (!rowAfterChange) {
  //     // Reached empty box, remove all connections
  //     return this._detachAttach(selectedVienti, currentRow, null)
  //   }

  //   return this._detachAttach(selectedVienti, currentRow, rowAfterChange)
  // }

  private _calcRowTotal(viennit: Reskontravienti[]): number {
    let total = 0
    if (!viennit?.length) {
      return null
    }

    for (const v of viennit) {
      if (v.d === 'k') {
        total += (-1 * v.s)
      } else {
        total += v.s
      }
    }
    return this._currencyService.roundHalfUp(total, 2)
  }

  private _getClosedDateIfClosed(viennit: Reskontravienti[]): Reskontravienti['c'] {
    let closedDate = null
    if (this._calcRowTotal(viennit) === 0) {
      closedDate = Math.max(...viennit.map(v => v.p))
    }
    return closedDate
  }

  onOpenKirjaus(data: { kirjausAvain: string, pvm: NumberDate, maksutapa: number }) {
    this.openKirjaus.emit(data)
  }

  private async _waitForAndScrollToVienti(vientiAvain: string): Promise<HTMLDivElement> {
    return this._ngZone.runOutsideAngular(async () => {
      const query = 'div[data-vientiavain="' + vientiAvain + '"]'
      let div: HTMLDivElement = document.querySelector(query)
      while (!div) {
        await this._sleep(50)
        div = document.querySelector(query)
      }
      this._scrollToDiv(div)
      return div as HTMLDivElement
    })
  }

  private _scrollToDiv(element: HTMLDivElement) {
    const componentElement = this._elRef.nativeElement
    const vientiRect = element.getBoundingClientRect()
    const componentRect = componentElement.getBoundingClientRect()

    const scrollaa = vientiRect.top !== componentRect.top
    if (scrollaa) {
      const move = vientiRect.top - componentRect.top - componentRect.height / 2
      const xCoordinate = componentElement.scrollLeft
      const yCoordinate = Math.max(componentElement.scrollTop + move, 0)

      componentElement.scrollTo({
        behavior: 'smooth',
        left: xCoordinate,
        top: yCoordinate
      })
    }
  }
  private _sleep(millis: number): Promise<void> {
    return this._ngZone.runOutsideAngular(() => {
      return new Promise<void>(resolve => {
        setTimeout(resolve, millis)
      })
    })
  }

  private _selectVientiByAvain(vientiAvain: string) {
    if (!this.renderableReskontraGroups?.length) {
      return
    }

    for (const group of this.renderableReskontraGroups) {
      for (const leftCol of group.leftColumnViennit) {
        if (leftCol.av === vientiAvain) {
          this.selectedVientiSubject.next(Object.assign(leftCol, { order: group.order }))
          return
        }
      }
      for (const rightCol of group.rightColumnViennit) {
        if (rightCol.av === vientiAvain) {
          this.selectedVientiSubject.next(Object.assign(rightCol, { order: group.order }))
          return
        }
      }
    }
  }

  async downloadPdf() {

    this._ladataanService.aloitaLataaminen()
    const reskontraTilinNimi = this.valittuTili.value
    const reskontraTili = reskontraTilinNimi?.split(' ')?.[0]

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const req: RaporttiRequest = {
      a: asiakas.avain,
      k: 'fi',
      w: RaporttiType.RESKONTRA,
      e: this._dateService.dateToNumber(this.pvm.value),
      rv: this.vainAvoimet.value,
      rk: reskontraTili
    }
    return this._firebase.functionsCall<RaporttiRequest, RaporttiPdfResponse>('kirjanpitoRaportitPdf', req).then(data => {
      if (data.e) {
        switch (data.e) {
          case 'unknown-report': {
            this._snackbar.open('Raporttia ei ole vielä implementoitu.', 'OK')
            break
          }
          case 'view-not-allowed': {
            this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
            break
          }
          default: {
            this._snackbar.open('Tietojen lataaminen epäonnistui.', 'OK')
          }
        }
      } else {
        if (data.base64File) {
          const fileName = data.fileName || 'reskontraraportti.pdf'
          this._fileSaverService.saveBase64As(data.base64File, fileName, 'pdf')
        } else {
          this._snackbar.open('PDF:n lataaminen epäonnistui.')
        }
      }
      this._ladataanService.lopetaLataaminen()
    })
  }

  // private _scrollaaVientiNakyviin(kirjausElementti: HTMLElement): Promise<void> {
  //   const boundRect = kirjausElementti.getBoundingClientRect()
  //   const scrollaaAlas = boundRect.top > window.innerHeight / 2
  //   if (scrollaaAlas) {

  //     // console.log('Scrollaa alas')
  //     const move = boundRect.top - window.innerHeight / 2

  //     // SCROLLAA VAIN NÄKYVIIN
  //     const yCoordinate = Math.max(window.screenY + move, 0)
  //     const xCoordinate = window.screenX
  //     // const header = document.querySelector('.k-sticky-header')
  //     // const yOffset = header ? (0 - (header.getBoundingClientRect().height + 10)) : -200
  //     if (yCoordinate !== window.screenY) {
  //       console.log('SCROLL ALAS', yCoordinate, window.screenY)
  //       window.scrollTo({
  //         behavior: 'auto',
  //         left: xCoordinate,
  //         top: yCoordinate
  //       })
  //       return this.scrollingHasStopped()
  //     }
  //   } else {
  //     // const header = document.querySelector('.k-sticky-header')
  //     // const bottomOfHeaders = header ? header.getBoundingClientRect().bottom : 200
  //     const scrollaa = boundRect.top < window.innerHeight / 2
  //     if (scrollaa) {
  //       // console.log('Scrollaa ylös', boundRect.top, bottomOfHeaders)
  //       const move = window.innerHeight / 2 - boundRect.top
  //       // SCROLLAA VAIN NÄKYVIIN
  //       const yCoordinate = Math.max(window.page - move, 0)
  //       const xCoordinate = window.pageXOffset
  //       if (yCoordinate !== window.pageYOffset) {
  //         // console.log('SCROLL YLÖS', yCoordinate, window.pageYOffset)
  //         window.scrollTo({
  //           behavior: 'auto',
  //           left: xCoordinate,
  //           top: yCoordinate
  //         })
  //         // console.log('Aloitetaan Scrollaaminen')
  //         return this.scrollingHasStopped()
  //       }
  //     }
  //   }
  // }



  // private _addCachedChange(changedVientiAvain: string, newConnections: Reskontravienti['a']) {
  //   const existingInCacheIdx = this._cachedReskontraChanges.findIndex(c => c.changedVientiAvin === changedVientiAvain)
  //   if (existingInCacheIdx > -1) {
  //     this._cachedReskontraChanges.splice(existingInCacheIdx, 1)
  //   }

  //   this._cachedReskontraChanges.push({ changedVientiAvin: changedVientiAvain, newConnections: newConnections })

  //   this._cacheChangedSubject.next(true)
  // }

  // startDrag(event: DragEvent, vienti: Reskontravienti, startRow: RenderableReskontraRow) {
  //   // Select item manually during the drag.
  //   this.selectedVientiSubject.next(Object.assign(vienti, { order: startRow.order }))
  //   event.dataTransfer.setData('startRow', JSON.stringify(startRow))
  //   document.body.classList.add('dragging')
  // }

  // onDragEnter(event: DragEvent) {
  //   const element = event.target as HTMLElement
  //   element.classList.add('drag-remove-selected')
  // }

  // onDragLeave(event: DragEvent) {
  //   const element = event.target as HTMLElement
  //   element.classList.remove('drag-remove-selected')
  // }

  // onDrop(event: DragEvent, endRow: RenderableReskontraRow) {
  //   const selectedVienti = this.selectedVientiSubject.value

  //   const startRowAsString = event.dataTransfer.getData('startRow')
  //   const startRow: RenderableReskontraRow = JSON.parse(startRowAsString)

  //   const newLeftConnections = endRow?.leftColumnViennit?.filter(v => v.av !== selectedVienti.av) ?? []
  //   const newRightConnections = endRow?.rightColumnViennit?.filter(v => v.av !== selectedVienti.av) ?? []

  //   if (!newLeftConnections && newRightConnections) {
  //     // Dropped to empty box, remove all connections
  //     return this._detachAttach(selectedVienti, startRow, null)
  //   }

  //   return this._detachAttach(selectedVienti, startRow, endRow)

  // }


  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
