import { Lasku } from '../../_jaettu/model/lasku'
import { FirestoreTosite } from '../../_jaettu/model/tosite'
import { Asiakas, Tilikausi } from '../model/asiakas'
import { KirjattavaLasku, KirjattavaKuitti, KirjattavaLaskuKirjauksessa, KirjattavaKuittiKirjauksessa, AlvIlmoituksenTunnistetiedot, VirallinenRaporttiName, RaporttiName, TilinpaatosRaporttiName } from '../model/kirjanpito'
import { LocalDate, LocalMonth, NumberMonth } from '../../_shared-core/model/common'

export class KirjanpitoUriService {

  annaAsiakkaanTilikartanUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/muut/tilikartta'
  }

  // Lemonator Firestore work queue
  annaTyojonoKirjauksenPoistonJalkeinenSelvitettavatSiivousUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjauksen_poisto_siivoa_selvitettavat/' + tyoAvain
  }

  annaTyojonoKirjausnumerojenPaivitysUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjausnumeron_paivittaminen/' + tyoAvain
  }

  annaTyojonoKuukaudenErotusPaivitysUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjaus_kuukauden_erotus_paivittaminen/' + tyoAvain
  }

  annaTyojonoKuukaudenLukujenPaivitysUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjaus_kuukauden_lukujen_paivittaminen/' + tyoAvain
  }

  annaTyojonoTilikaudenLukujenPaivitysUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjaus_tilikauden_lukujen_paivittaminen/' + tyoAvain
  }

  annaTyojonoKuukaudenAlvIlmoitusdatanPaivitysUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjaus_kuukauden_alvilmo_paivittaminen/' + tyoAvain
  }

  annaTyojonoKirjanpitoTilikausiValmiiksiUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjanpito_tilikausi_valmiiksi/' + tyoAvain
  }

  annaTyojonoKirjausnumerojenPaivitysTyojonodatastaUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjausnumeron_paivittaminen_tyojonodatasta/' + tyoAvain
  }

  annaTyojonoKirjausnumerojenPaivitysTyojonodataUri(asiakasAvain: string, datanAvain: string): string {
    return 'tyojono_data/' + asiakasAvain + '/kirjanpito_kirjausnumero_laske/' + datanAvain
  }

  annaTyojonoKirjanpitoAutomaattistenPoistojenPaivitysUri(asiakasAvain: string, tyoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjaus_automaattiset_poistot/' + tyoAvain
  }

  // Kirjaukset
  annaPalkkausfiKirjauksenAvain(asiakasAvain: string, month: LocalMonth): string {
    return asiakasAvain + '_palkat_' + month.year + '_' + month.month
  }
  annaPalkkausFiKirjaukseenLiitettyjenTiedostojenUri(asiakasAvain: string, month: LocalMonth): string {
    const kirjausAvain = this.annaPalkkausfiKirjauksenAvain(asiakasAvain, month)
    return this.annaKirjaukseenLiitettyjenTiedostojenUri(asiakasAvain, kirjausAvain)
  }
  annaPalkkausfiKirjauksenUri(asiakasAvain: string, month: LocalMonth): string {
    const kirjausAvain = this.annaPalkkausfiKirjauksenAvain(asiakasAvain, month)
    return this.annaKirjausCollectionUri(asiakasAvain) + '/' + kirjausAvain
  }

  annaOmaverotasmaytyksenKuukausiKirjauksenAvain(asiakasAvain: string, month: LocalMonth): string {
    return asiakasAvain + '_omaverotasmaytys_kuukausittaiset_' + month.year + '_' + month.month
  }
  annaOmaverotasmaytyksenKuukausiKirjauksenUri(asiakasAvain: string, month: LocalMonth): string {
    const kirjausAvain = this.annaOmaverotasmaytyksenKuukausiKirjauksenAvain(asiakasAvain, month)
    return this.annaKirjausCollectionUri(asiakasAvain) + '/' + kirjausAvain
  }

  annaOmaverotasmaytyksenErapaivaKirjauksenAvain(asiakasAvain: string, dueDate: LocalDate): string {
    return asiakasAvain + '_omaverotasmaytys_erapaivat_' + dueDate.year + '_' + dueDate.month + '_' + dueDate.day
  }
  annaOmaverotasmaytyksenErapaivaKirjauksenUri(asiakasAvain: string, dueDate: LocalDate): string {
    const kirjausAvain = this.annaOmaverotasmaytyksenErapaivaKirjauksenAvain(asiakasAvain, dueDate)
    return this.annaKirjausCollectionUri(asiakasAvain) + '/' + kirjausAvain
  }

  annaOmaverotasmaytyksenTmiTilikausiKirjauksenAvain(asiakasAvain: string, tilikausiAvain: string): string {
    return asiakasAvain + '_omaverotasmaytys_tmi_tilikausi_' + tilikausiAvain
  }
  annaOmaverotasmaytyksenTmiTilikausiKirjauksenUri(asiakasAvain: string, tilikausiAvain: string): string {
    const kirjausAvain = this.annaOmaverotasmaytyksenTmiTilikausiKirjauksenAvain(asiakasAvain, tilikausiAvain)
    return this.annaKirjausCollectionUri(asiakasAvain) + '/' + kirjausAvain
  }
  annaKulujenKuukausijaksotuksenKirjauksenAvain(alkuperaisenKirjauksenAvain: string, kuukausiTaiNollaus: NumberMonth | 'nollaus') {
    return alkuperaisenKirjauksenAvain + '_jaksotus_' + kuukausiTaiNollaus
  }

  annaKirjauksenUri(asiakasAvain: string, kirjausAvain: string): string {
    if (!kirjausAvain) {
      throw new Error('Missing kirjausavain for customer ' + asiakasAvain + '!!')
    }
    return this.annaKirjausCollectionUri(asiakasAvain) + '/' + kirjausAvain
  }

  annaTmiTilienTyhjennyskirjauksenAvain(tilikausi: Tilikausi): string {
    return tilikausi.avain + this.annaTmiTilikausienTyhjennyskirjauksenMarker()
  }

  annaTmiTilikausienTyhjennyskirjauksenMarker(): string {
    return '_yksityistilin_tyhjentaminen'
  }

  annaVeroilmoituksenDraftCollectionUri(asiakasAvain: string,) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-veroilmoitus-draft/'
  }

  annaVeroilmoituksenDraftUri(asiakasAvain: string, tilikausi: Tilikausi) {
    return this.annaVeroilmoituksenDraftCollectionUri(asiakasAvain) + tilikausi.avain
  }

  annaVeroilmoituksenFinalCollectionUri(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-veroilmoitus-final'
  }

  annaVeroilmoituksenFinalUri(asiakasAvain: string, avain: string) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-veroilmoitus-final/' + avain
  }

  // annaVeroilmoituksenOsakkaatUri(asiakasAvain: string, tilikausiAvain: string) {
  //   return 'asiakkaat/' + asiakasAvain + '/kirjanpito-veroilmoitus-osakkaat/' + tilikausiAvain
  // }

  annaVeroilmoituksenOsakkaatHistoriaUri(asiakasAvain: string, tilikausiAvain: string, historiaAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-veroilmoitus-osakkaat/' + tilikausiAvain + '/kirjanpito-veroilmoitus-osakkaat-historia/' + historiaAvain
  }

  annaTilikaudenTilisummienCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-tilikauden-summat'
  }

  annaTilikaudenTilisummienUri(asiakasAvain: string, tilikausi: Tilikausi) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-tilikauden-summat/' + tilikausi.avain
  }

  annaTilinpaatosTaseErittelyUri(asiakasAvain: string, tilikausi: Tilikausi): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-tilinpaatos-taseerittely/' + tilikausi.avain
  }

  annaTilinpaatosTaseErittelyHistoriaUri(asiakasAvain: string, tilikausi: Tilikausi, historiaAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-tilinpaatos-taseerittely/' + tilikausi.avain + '/kirjanpito-tilinpaatos-taseerittely-historia/' + historiaAvain
  }

  annaTilinpaatosLiitetiedotUri(asiakasAvain: string, tilikausi: Tilikausi): string {
    return this.annaTilinpaatosLiitetiedotUriAvaimella(asiakasAvain, tilikausi.avain)
  }

  annaTilinpaatosLiitetiedotUriAvaimella(asiakasAvain: string, tilikausiAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-tilinpaatos-liitetiedot/' + tilikausiAvain
  }

  annaTilinpaatosLiitetiedotHistoriaUri(asiakasAvain: string, tilikausi: Tilikausi, historiaAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-tilinpaatos-liitetiedot/' + tilikausi.avain + '/kirjanpito-tilinpaatos-liitetiedot-historia/' + historiaAvain
  }

  annaTilinpaatosLiitetiedotBinaariliiteStorageUri(asiakasAvain: string, tilikausiAvain: string, liiteAvain: string, fileType: string) {
    return asiakasAvain + '/tilinpaatos/' + tilikausiAvain + '/' + liiteAvain + '.' + fileType
  }
  annaTaseErittelyLiiteStorageUri(asiakasAvain: string, tilikausiAvain: string, liiteAvain: string, fileType: string) {
    return asiakasAvain + '/tilinpaatos-taseerittely/' + tilikausiAvain + '/' + liiteAvain + '.' + fileType
  }

  annaTilinpaatosTilintarkastustiedotUri(asiakasAvain: string, tilikausi: Tilikausi): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-tilinpaatos-tilintarkastus/' + tilikausi.avain
  }

  annaTilinpaatosTilintarkastustiedotHistoriaUri(asiakasAvain: string, tilikausi: Tilikausi, historiaAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-tilinpaatos-tilintarkastus/' + tilikausi.avain + '/kirjanpito-tilinpaatos-tilintarkastus-historia/' + historiaAvain
  }

  annaTilinpaatosTilintarkastusLadattuTyojonoUri(asiakasAvain: string, tyojonoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/tilintarkastuskertomus-ladattu/' + tyojonoAvain
  }

  annaTilinpaatosLiitetiedotTallennettuTyojonoUri(asiakasAvain: string, tyojonoAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/liitetiedot-tallennettu/' + tyojonoAvain
  }

  annaTilinpaatosTilintarkastusBinaariliiteStorageUri(asiakasAvain: string, tilikausiAvain: string, liiteAvain: string, fileType: string) {
    return asiakasAvain + '/tilintarkastus/' + tilikausiAvain + '/' + liiteAvain + '.' + fileType
  }

  annaTilinpaatosTempBinaariliiteStorageUri(asiakasAvain: string, tilikausiAvain: string, liiteAvain: string, fileType: string) {
    return asiakasAvain + '/temp/' + tilikausiAvain + '/' + liiteAvain + '.' + fileType
  }

  annaKirjaukseenLiitettyjenTiedostojenCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-kirjaukset-liitetyt-tiedostot'
  }

  annaKirjaukseenLiitettyjenTiedostojenUri(asiakasAvain: string, kirjausAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-kirjaukset-liitetyt-tiedostot/' + kirjausAvain
  }

  annaKirjaukseenLiitettyjenTiedostojenHistoriaCollectionUri(asiakasAvain: string, kirjausAvain: string): string {
    return this.annaKirjaukseenLiitettyjenTiedostojenUri(asiakasAvain, kirjausAvain) + '/kirjanpito-kirjaukset-liitetyt-tiedostot-historia'
  }

  annaKirjaukseenLiitettyjenTiedostojenHistoriaUri(asiakasAvain: string, kirjausAvain: string, historiaAvain: string): string {
    return this.annaKirjaukseenLiitettyjenTiedostojenUri(asiakasAvain, kirjausAvain) + '/kirjanpito-kirjaukset-liitetyt-tiedostot-historia/' + historiaAvain
  }

  annaKirjauksenHistoriaUri(asiakasAvain: string, kirjausAvain: string, kirjausHistoriaAvain: string): string {
    return this.annaKirjausCollectionUri(asiakasAvain) + '/' + kirjausAvain + '/kirjanpito-kirjaukset-versiot/' + kirjausHistoriaAvain
  }

  annaKirjauksenHistoriaCollectionUri(asiakasAvain: string, kirjausAvain: string): string {
    return this.annaKirjausCollectionUri(asiakasAvain) + '/' + kirjausAvain + '/kirjanpito-kirjaukset-versiot'
  }

  annaKirjausCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-kirjaukset'
  }

  annaAlvIlmoituksenUri(asiakasAvain: string, alvilmoitusAvain: string): string {
    return this.annaAlvIlmoituksenCollectionUri(asiakasAvain) + '/' + alvilmoitusAvain
  }

  annaAlvIlmoituksenDraftAvain(asiakasAvain: string, tunnistetiedot: AlvIlmoituksenTunnistetiedot) {
    return asiakasAvain + '_' + tunnistetiedot.alvIlmoitusjakso + '_' + tunnistetiedot.vuosi + '_' + tunnistetiedot.jarjestys + '_draft'
  }

  annaAlvIlmoituksenDraftUri(asiakasAvain: string, tunnistetiedot: AlvIlmoituksenTunnistetiedot): string {
    return this.annaAlvIlmoituksenDraftCollectionUri(asiakasAvain) + '/' + this.annaAlvIlmoituksenDraftAvain(asiakasAvain, tunnistetiedot)
  }

  // annaTyojonoAlvIlmoituksenLahettaminenUri(asiakasAvain: string, alvilmoitusAvain: string): string {
  //   return 'tyojono/' + asiakasAvain + '/kirjanpito-alv-ilmoituksen-lahetys/' + alvilmoitusAvain
  // }

  annaTyojonoAlvIlmoituksenLahettaminenUusiUri(asiakasAvain: string, alvilmoitusAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjanpito-alv-ilmoituksen-lahetys-uusi/' + alvilmoitusAvain
  }

  annaTyojonoAlvIlmoituksenIlmoitinLahettaminenUri(asiakasAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjanpito-alv-ilmoituksen-lahetys-ilmoitin/' + asiakasAvain
  }

  annaTyojonoVeroilmoituksenIlmoitinLahettaminenUri(asiakasAvain: string): string {
    return 'tyojono/' + asiakasAvain + '/kirjanpito-veroilmoituksen-lahetys-ilmoitin/' + asiakasAvain
  }

  annaAlvIlmoituksenHistoriaUri(asiakasAvain: string, alvilmoitusAvain: string, alvilmoitusHistoriaAvain: string): string {
    return this.annaAlvIlmoituksenCollectionUri(asiakasAvain) + '/' + alvilmoitusAvain + '/kirjanpito-alvilmoitukset-versiot/' + alvilmoitusHistoriaAvain
  }

  annaAlvIlmoituksenDraftCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-alvilmoitukset-draft'
  }

  annaAlvIlmoituksenCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-alvilmoitukset'
  }

  annaRaporttikirjausCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-raporttikirjaukset'
  }

  annaKirjattavatLaskutCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-kirjattavat-laskut'
  }

  annaKirjattavatFirestoreTositteetCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-kirjattavat-kuitit'
  }

  annaKirjattavatRaahatutCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-kirjattavat-raahatut'
  }

  annaRaportointikirjauksenUri(asiakasAvain: string, kirjausAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-raporttikirjaukset/' + kirjausAvain
  }

  annaRaportointikirjauksetPaivitettyUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-raporttikirjaukset-paivitetty/' + asiakasAvain
  }

  annaKirjattavanLaskunUriKirjattavallaLaskulla(asiakas: Asiakas, lasku: KirjattavaLasku | KirjattavaLaskuKirjauksessa): string {
    return this.annaKirjattavanLaskunUriAvaimella(asiakas.avain, lasku.avain)
  }

  annaKirjattavanLaskunUriLaskulla(asiakas: Asiakas, lasku: Lasku): string {
    return this.annaKirjattavanLaskunUriAvaimella(asiakas.avain, lasku.avain)
  }

  annaKirjattavanLaskunUriAvaimella(asiakasAvain: string, laskuAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-kirjattavat-laskut/' + laskuAvain
  }

  annaKirjattavanLaskunHistoriaUri(asiakasAvain: string, laskuAvain: string, historiaAvain: string): string {
    return this.annaKirjattavanLaskunUriAvaimella(asiakasAvain, laskuAvain) + '/kirjanpito-kirjattavat-laskut-historia/' + historiaAvain
  }

  annaKirjattavanRaahatunUri(asiakasAvain: string, raahatunAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-kirjattavat-raahatut/' + raahatunAvain
  }

  annaKirjattavanRaahatunHistorianUri(asiakasAvain: string, raahatunAvain: string, historianAvain: string): string {
    return this.annaKirjattavanRaahatunUri(asiakasAvain, raahatunAvain) + '/kirjanpito-kirjattava-raahattu-historia/' + historianAvain
  }

  annaKirjattavanFirestoreTositteenUriTositteella(asiakas: Asiakas, tosite: FirestoreTosite): string {
    return this.annaKirjattavanFirestoreTositteenUriAvaimella(asiakas.avain, tosite.avain)
  }

  annaKirjattavanFirestoreTositteenUriKirjattavallaTositteella(asiakas: Asiakas, kirjattavaTosite: KirjattavaKuitti | KirjattavaKuittiKirjauksessa): string {
    return this.annaKirjattavanFirestoreTositteenUriAvaimella(asiakas.avain, kirjattavaTosite.avain)
  }

  annaKirjattavanFirestoreTositteenUriAvaimella(asiakasAvain: string, tositeAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-kirjattavat-kuitit/' + tositeAvain
  }

  annaKirjattavanFirestoreTositteenHistoriaUriTositteella(asiakas: Asiakas, tosite: FirestoreTosite, historiaAvain: string): string {
    return this.annaKirjattavanFirestoreTositteenHistoriaUriAvaimella(asiakas.avain, tosite.avain, historiaAvain)
  }

  annaKirjattavanFirestoreTositteenHistoriaUriAvaimella(asiakasAvain: string, tositeAvain: string, historiaAvain: string): string {
    return this.annaKirjattavanFirestoreTositteenUriAvaimella(asiakasAvain, tositeAvain) + '/kirjanpito-kirjattavat-kuitit-historia/' + historiaAvain
  }

  // Kirjanpito sposti lähetys - Firestore
  annaKirjanpitoEmailTekstiAutosaveCollection(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-email-lahetykset-luonnokset/'
  }
  annaKirjanpitoEmailTekstiAutosaveUri(asiakasAvain: string, kuukausi: LocalMonth): string {
    return this.annaKirjanpitoEmailTekstiAutosaveCollection(asiakasAvain) + asiakasAvain + '_' + this._kuukausiNumeroksi(kuukausi)
  }
  annaVeroilmoitusEmailTekstiAutosaveCollection(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/veroilmoitus-email-lahetykset-luonnokset/'
  }
  annaVeroilmoitusEmailTekstiAutosaveUri(asiakasAvain: string, tilikausi: Tilikausi): string {
    return 'asiakkaat/' + asiakasAvain + '/veroilmoitus-email-lahetykset-luonnokset/' + tilikausi.avain + '_veroilmoitus'
  }
  annaTilinpaatosEmailTekstiAutosaveCollection(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/tilinpaatos-email-lahetykset-luonnokset/'
  }
  annaTilinpaatosEmailTekstiAutosaveUri(asiakasAvain: string, tilikausi: Tilikausi): string {
    return 'asiakkaat/' + asiakasAvain + '/tilinpaatos-email-lahetykset-luonnokset/' + tilikausi.avain + '_tilinpaatos'
  }
  annaKirjanpitoEmailLahetyksetCollection(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-email-lahetykset'
  }
  annaKirjanpitoEmailLahetyksetLahetysAvaimella(asiakasAvain: string, lahetysAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-email-lahetykset/' + lahetysAvain
  }
  annaKirjanpitoEmailLahetyksenLiitteetCollection(asiakasAvain: string, lahetysAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-email-lahetykset/' + lahetysAvain + '/kirjanpito-email-lahetyksen-liitteet'
  }
  annaKirjanpitoEmailLahetyksenLiitteenUri(asiakasAvain: string, lahetysAvain: string, liiteAvain: string): string {
    return this.annaKirjanpitoEmailLahetyksenLiitteetCollection(asiakasAvain, lahetysAvain) + '/' + liiteAvain
  }
  annaVeroilmoitusEmailLahetyksetCollection(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/veroilmoitus-email-lahetykset'
  }
  annaVeroilmoitusEmailLahetyksetLahetysAvaimella(asiakasAvain: string, lahetysAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/veroilmoitus-email-lahetykset/' + lahetysAvain
  }
  annaVeroilmoitusEmailLahetyksenLiitteetCollection(asiakasAvain: string, lahetysAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/veroilmoitus-email-lahetykset/' + lahetysAvain + '/veroilmoitus-email-lahetyksen-liitteet'
  }
  annaVeroilmoitusEmailLahetyksenLiitteenUri(asiakasAvain: string, lahetysAvain: string, liiteAvain: string): string {
    return this.annaVeroilmoitusEmailLahetyksenLiitteetCollection(asiakasAvain, lahetysAvain) + '/' + liiteAvain
  }
  annaTilinpaatosEmailLahetyksetCollection(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/tilinpaatos-email-lahetykset'
  }
  annaTilinpaatosEmailLahetyksetLahetysAvaimella(asiakasAvain: string, lahetysAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/tilinpaatos-email-lahetykset/' + lahetysAvain
  }
  annaTilinpaatosEmailLahetyksenLiitteetCollection(asiakasAvain: string, lahetysAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/tilinpaatos-email-lahetykset/' + lahetysAvain + '/tilinpaatos-email-lahetyksen-liitteet'
  }
  annaTilinpaatosEmailLahetyksenLiitteenUri(asiakasAvain: string, lahetysAvain: string, liiteAvain: string): string {
    return this.annaTilinpaatosEmailLahetyksenLiitteetCollection(asiakasAvain, lahetysAvain) + '/' + liiteAvain
  }
  annaTilinpaatosLahetysCollectionUri(asiakasAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/tilinpaatos-lahetykset'
  }
  annaTilinpaatosLahetysUri(asiakasAvain: string, lahetysAvain: string): string {
    return 'asiakkaat/' + asiakasAvain + '/tilinpaatos-lahetykset/' + lahetysAvain
  }
  annaTilinpaatosLahetysTyojonoUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-laheta/' + tyojonoAvain
  }
  annaTilinpaatosEmailLahetysTyojonoEnsimmainenCollection(asiakasAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-laheta-email-1-create-pdfs'
  }
  annaTilinpaatosEmailLahetysTyojonoEnsimmainenUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-laheta-email-1-create-pdfs/' + tyojonoAvain
  }
  annaTilinpaatosEmailLahetysTyojonoToinenCollection(asiakasAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-laheta-email-2-send-via-postmark'
  }
  annaTilinpaatosEmailLahetysTyojonoToinenUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-laheta-email-2-send-via-postmark/' + tyojonoAvain
  }
  annaKirjanpitoEmailLahetysTyojonoEnsimmainenCollection(asiakasAvain: string) {
    return 'tyojono/' + asiakasAvain + '/kirjanpito-laheta-email-1-create-pdfs'
  }
  annaKirjanpitoEmailLahetysTyojonoEnsimmainenUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/kirjanpito-laheta-email-1-create-pdfs/' + tyojonoAvain
  }
  annaKirjanpitoEmailLahetysTyojonoToinenCollection(asiakasAvain: string) {
    return 'tyojono/' + asiakasAvain + '/kirjanpito-laheta-email-2-send-via-postmark'
  }
  annaKirjanpitoEmailLahetysTyojonoToinenUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/kirjanpito-laheta-email-2-send-via-postmark/' + tyojonoAvain
  }
  annaVeroilmoitusEmailLahetysTyojonoEnsimmainenCollection(asiakasAvain: string) {
    return 'tyojono/' + asiakasAvain + '/veroilmoitus-laheta-email-1-create-pdfs'
  }
  annaVeroilmoitusEmailLahetysTyojonoEnsimmainenUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/veroilmoitus-laheta-email-1-create-pdfs/' + tyojonoAvain
  }
  annaVeroilmoitusEmailLahetysTyojonoToinenCollection(asiakasAvain: string) {
    return 'tyojono/' + asiakasAvain + '/veroilmoitus-laheta-email-2-send-via-postmark'
  }
  annaVeroilmoitusEmailLahetysTyojonoToinenUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/veroilmoitus-laheta-email-2-send-via-postmark/' + tyojonoAvain
  }
  getTilinpaatosAllSignedWorkQueueDocUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-all-users-signed/' + tyojonoAvain
  }
  getYhtiokokousAllSignedWorkQueueDocUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/yhtiokokous-all-users-signed/' + tyojonoAvain
  }
  getYhtiokokousFirstSignedWorkQueueDocUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/yhtiokokous-first-user-signed/' + tyojonoAvain
  }
  getTilinpaatosRegisterWorkQueueDocUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-rekisterointi/' + tyojonoAvain
  }
  getTilinpaatosRegisterSendToPrhWorkQueueDocUri(asiakasAvain: string, tyojonoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-laheta-prh/' + tyojonoAvain
  }
  // Kirjanpito sposti lähetys - Cloud Storage
  annaKirjanpitoEmailLahetyksenRaporttienStorageFolder(asiakasAvain: string, lahetysAvain: string) {
    return 'kirjanpito-email-lahetykset/' + asiakasAvain + '/' + lahetysAvain
  }
  annaKirjanpitoEmailLahetyksenRaportinStorageUri(asiakasAvain: string, lahetysAvain: string, typeName: RaporttiName) {
    return 'kirjanpito-email-lahetykset/' + asiakasAvain + '/' + lahetysAvain + '/' + typeName + '.pdf'
  }

  // Veroilmoitus sposti lähetys - Cloud Storage
  annaVeroilmoitusEmailLahetyksenRaporttienStorageFolder(asiakasAvain: string, lahetysAvain: string) {
    return 'veroilmoitus-email-lahetykset/' + asiakasAvain + '/' + lahetysAvain
  }
  annaVeroilmoitusEmailLahetyksenRaportinStorageUri(asiakasAvain: string, lahetysAvain: string, typeName: VirallinenRaporttiName) {
    return 'veroilmoitus-email-lahetykset/' + asiakasAvain + '/' + lahetysAvain + '/' + typeName + '.pdf'
  }

  // Veroilmoitus sposti lähetys - Cloud Storage
  annaTilinpaatosEmailLahetyksenRaportinStorageFolder(asiakasAvain: string, lahetysAvain: string) {
    return 'tilinpaatos-email-lahetykset/' + asiakasAvain + '/' + lahetysAvain
  }
  annaTilinpaatosEmailLahetyksenRaportinStorageUri(asiakasAvain: string, lahetysAvain: string, typeName: TilinpaatosRaporttiName) {
    return 'tilinpaatos-email-lahetykset/' + asiakasAvain + '/' + lahetysAvain + '/' + typeName + '.pdf'
  }

  // Sposti lähetys - shared
  annaEmailLahetystenLiitteidenStorageFolder(asiakasAvain: string) {
    return asiakasAvain + '/email-attachments'
  }
  annaEmailLahetyksenLiiteStorageUri(asiakasAvain: string, liiteAvain: string, fileType: string) {
    return this.annaEmailLahetystenLiitteidenStorageFolder(asiakasAvain) + '/' + liiteAvain + '.' + fileType
  }

  // Kirjanpito sposti lähetys - email tag
  teeLahetysEmailTag(lahetysUri: string): string {
    return 'kirjanpito1_____' + lahetysUri
  }
  // Veroilmoitus sposti lähetys - email tag
  teeVeroilmoitusLahetysEmailTag(lahetysUri: string): string {
    return 'veroilmoitus1_____' + lahetysUri
  }
  // Tilinpäätös sposti lähetys - email tag
  teeTilinpaatosLahetysEmailTag(lahetysUri: string): string {
    return 'tilinpaatos1_____' + lahetysUri
  }
  puraLahetysEmailTag(tag: string): { lahetysUri: string, lahetysAvain: string, asiakasAvain: string } {
    const split = tag.replace('kirjanpito1_____', '').split('_____')
    const lahetysUri = split[0]
    const asiakasAvain = tag.replace('kirjanpito1_____/asiakkaat/', '').substring(0, tag.indexOf('/kirjanpito-email-lahetykset/'))
    const lahetysAvain = lahetysUri.replace('/asiakkaat/' + asiakasAvain + '/kirjanpito-email-lahetykset/', '')
    return {
      lahetysUri: lahetysUri,
      asiakasAvain: asiakasAvain,
      lahetysAvain: lahetysAvain
    }
  }
  puraVeroilmoitusLahetysEmailTag(tag: string): { lahetysUri: string, lahetysAvain: string, asiakasAvain: string } {
    const split = tag.replace('veroilmoitus1_____', '').split('_____')
    const lahetysUri = split[0]
    const asiakasAvain = tag.replace('veroilmoitus1_____/asiakkaat/', '').substring(0, tag.indexOf('/verilmoitus-email-lahetykset/'))
    const lahetysAvain = lahetysUri.replace('/asiakkaat/' + asiakasAvain + '/veroilmoitus-email-lahetykset/', '')
    return {
      lahetysUri: lahetysUri,
      asiakasAvain: asiakasAvain,
      lahetysAvain: lahetysAvain
    }
  }
  puraTilinpaatosLahetysEmailTag(tag: string): { lahetysUri: string, lahetysAvain: string, asiakasAvain: string } {
    const split = tag.replace('tilinpaatos1_____', '').split('_____')
    const lahetysUri = split[0]
    const asiakasAvain = tag.replace('tilinpaatos1_____/asiakkaat/', '').substring(0, tag.indexOf('/tilinpaatos-email-lahetykset/'))
    const lahetysAvain = lahetysUri.replace('/asiakkaat/' + asiakasAvain + '/tilinpaatos-email-lahetykset/', '')
    return {
      lahetysUri: lahetysUri,
      asiakasAvain: asiakasAvain,
      lahetysAvain: lahetysAvain
    }
  }

  annaKirjanpitoProjektitCollection(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-projektit/'
  }
  annaKirjanpitoProjektinUri(asiakasAvain: string, projektiAvain: string) {
    return this.annaKirjanpitoProjektitCollection(asiakasAvain) + projektiAvain
  }

  annaBankTransactionsClosingBalancesUri(asiakasAvain: string, iban: string, kuukausi: LocalMonth, bankBalanceAlgoVersion?: string): string {
    return this.annaBankTransactionsClosingBalancesCollectionUri(asiakasAvain) + '/' + asiakasAvain + '_' + iban + '_' + this._kuukausiNumeroksi(kuukausi) + (bankBalanceAlgoVersion ? ('_' + bankBalanceAlgoVersion) : '')
  }

  annaBankTransactionsClosingBalancesCollectionUri(asiakasAvain: string) {
    return 'customers/' + asiakasAvain + '/bank-transactions-closing-balances'
  }

  annaTilikaudenAvainluvutUri(asiakasAvain: string, tilikausi: Tilikausi) {
    return 'asiakkaat/' + asiakasAvain + '/kirjanpito-tilikauden-avainluvut/' + tilikausi.avain
  }

  getTilinpaatosSendReminderTyojonoUri(asiakasAvain: string, tyoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/tilinpaatos-send-reminder/' + tyoAvain
  }

  getPoytakirjaSendReminderTyojonoUri(asiakasAvain: string, tyoAvain: string) {
    return 'tyojono/' + asiakasAvain + '/poytakirja-send-reminder/' + tyoAvain
  }

  private _kuukausiNumeroksi(kuukausi: LocalMonth): number {
    return Math.floor((kuukausi.year - 2000) * 100 + kuukausi.month)
  }

  annaOmaveroYhteisomyyntilaskuLahetettyCollectionUri(asiakasAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/yhteisomyynti-ilmoitukset/'
  }

  annaOmaveroYhteisomyyntilaskuLahetettyUri(asiakasAvain: string, lahetysAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/yhteisomyynti-ilmoitukset/' + lahetysAvain
  }

  annaOmaveroYhteisomyyntilaskuLahetettyDebugDataUri(asiakasAvain: string, lahetysAvain: string) {
    return 'asiakkaat/' + asiakasAvain + '/yhteisomyynti-ilmoitukset/' + lahetysAvain + '/yhteisomyynti-ilmoitukset-debug-data/' + lahetysAvain
  }



}
